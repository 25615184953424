import React, { useCallback, useEffect, useState } from 'react';
import { DEFAULT_VIDEO_CONSTRAINTS } from '../../../../constants';
import { LocalVideoTrack } from 'twilio-video';
import useMediaStreamTrack from '../../../../twilio/hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../../twilio/hooks/useVideoContext/useVideoContext';
import { useVideoInputDevices } from '../../../../twilio/hooks/deviceHooks/deviceHooks';
import { faRepeat } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import IHasStyle from '../../../../components/propInterfaces/IHasStyle';
import HashiControlFab from '../../../../components/HashiControlFab';

export default function FlipCameraButton(props: IHasStyle) {
    const { localTracks } = useVideoContext();
    const [
        supportsFacingMode,
        setSupportsFacingMode,
    ] = useState<Boolean | null>(null);
    const videoTrack = localTracks.find((track) =>
        track.name.includes('camera')
    ) as LocalVideoTrack;
    const mediaStreamTrack = useMediaStreamTrack(videoTrack);
    const videoDeviceList = useVideoInputDevices();

    useEffect(() => {
        // The 'supportsFacingMode' variable determines if this component is rendered
        // If 'facingMode' exists, we will set supportsFacingMode to true.
        // However, if facingMode is ever undefined again (when the user unpublishes video), we
        // won't set 'supportsFacingMode' to false. This prevents the icon from briefly
        // disappearing when the user switches their front/rear camera.
        const currentFacingMode = mediaStreamTrack?.getSettings().facingMode;
        if (currentFacingMode && supportsFacingMode === null) {
            setSupportsFacingMode(true);
        }
    }, [mediaStreamTrack, supportsFacingMode]);

    const toggleFacingMode = useCallback(() => {
        const newFacingMode =
            mediaStreamTrack?.getSettings().facingMode === 'user'
                ? 'environment'
                : 'user';
        videoTrack.restart({
            ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
            facingMode: newFacingMode,
        });
    }, [mediaStreamTrack, videoTrack]);

    function Button() {
        return (
            <HashiControlFab
                style={props.style}
                onClick={toggleFacingMode}
                isDisabled={!videoTrack}
            >
                <FontAwesomeIcon icon={faRepeat} size='2x' />
            </HashiControlFab>
        );
    }

    return supportsFacingMode && videoDeviceList.length > 1 ? <Button /> : null;
}
