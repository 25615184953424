import { adInterestsProvider } from '../../providers/AdInterestsProvider';
import { useState, useRef, MutableRefObject } from 'react';
import { Ad } from '../../models/Ad';
import { logger } from '../../../services';
import { adEventProvider } from '../../providers/AdEventProvider';
import { playlistProvider } from '../../providers/PlaylistProvider';

const adBuffer = 3;
var playlistAds: Ad[] = [];
var lastAdIndex: number = -1;
var lastActiveAd: Ad;

export default function useMakePlaylistViewModelValue(
    accessToken: string,
    onDisablePlaylist: (() => void) | undefined
): IPlaylistViewModel {
    const playlistAdsRef = useRef<Ad[]>(playlistAds);
    const [activeAdIndex, setActiveAdIndex] = useState(lastAdIndex);
    const [activeAd, setActiveAd] = useState<Ad | undefined>(lastActiveAd);
    const [isPaused, setIsPaused] = useState(false);
    const [isAudioMuted, setIsAudioMuted] = useState(true);
    const [isFavorite, setIsFavorite] = useState(false);
    const [adInterestsEnabled, setAdInterestsEnabled] = useState(true);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const videoPlayerRef = useRef<HTMLVideoElement>(null);

    const hasAds = () => {
        return playlistAdsRef.current.length > 0;
    };

    const setNewActiveAd = (index: number) => {
        lastAdIndex = index;
        lastActiveAd = playlistAdsRef.current[index];
        setActiveAdIndex(index);
        setActiveAd(lastActiveAd);
    };

    const refreshPlaylist = () => {
        setNewActiveAd(-1);
        setAdInterestsEnabled(true);
        playlistAds = [];
        playlistAdsRef.current = [];
        getMorePlaylistAds();
    };

    const startPlaylistAsync = async () => {
        if (activeAdIndex > -1) {
            return;
        }
        if (!hasAds()) {
            await getMorePlaylistAds();
        }
        if (!hasAds()) {
            onDisablePlaylist?.();
        }
        setNewActiveAd(0);
    };

    const advancePlayhead = () => {
        let newIndex = activeAdIndex + 1;
        setNewActiveAd(newIndex);

        //Ads that just started playing can't already be favorited.
        setIsFavorite(false);

        let getMoreAds = newIndex + adBuffer === playlistAdsRef.current.length;

        if (getMoreAds) {
            getMorePlaylistAds();
        }
    };

    const on2SecondPlay = async (ad: Ad) => {
        await adEventProvider.postPlayForTwoSecondsAsync(ad, accessToken);
    };

    const onAdEnd = async (ad: Ad) => {
        advancePlayhead();
        await adEventProvider.postCompletedAsync(ad, accessToken);
    };

    const onError = async (ad: Ad) => {
        advancePlayhead();
        logger.logMessage(`${ad.name} failed to play`);
        await adEventProvider.postFailedAsync(ad, accessToken);
    };

    const toggleAdFavoriteAsync = async () => {
        if (!activeAd) {
            return;
        }
        activeAd.isFavorite = !activeAd.isFavorite;
        setIsFavorite(activeAd.isFavorite);

        if (activeAd.isFavorite) {
            await SaveAdFavoriteAsync(activeAd);
        } else if (activeAd.AdInterestId) {
            await DeleteAdFavoriteAsync(activeAd.AdInterestId);
        }
    };

    const SaveAdFavoriteAsync = async (ad: Ad) => {
        await adInterestsProvider.saveAdInterestAsync(ad, accessToken);
    };

    const DeleteAdFavoriteAsync = async (interestId: number) => {
        await adInterestsProvider.deleteAdInterestAsync(
            interestId,
            accessToken
        );
    };

    const toggleFullScreen = () => {
        if (isFullScreen) {
            closeFullScreen();
        } else {
            openFullScreen();
        }
    };

    const openFullScreen = () => {
        setIsFullScreen(true);
    };

    const closeFullScreen = () => {
        setIsFullScreen(false);
    };

    const getMorePlaylistAds = async () => {
        try {
            var playlist = await playlistProvider.getNewPlaylist(accessToken);
            if (playlist?.ads?.length > 0) {
                playlistAds = [...playlistAds, ...playlist.ads];
                playlistAdsRef.current = playlistAds;
                setAdInterestsEnabled(!!playlist.adInterestsEnabled);
            }
        } catch (ex) {
            logger.logError(ex);
        }
    };

    return {
        adInterestsEnabled,
        activeAd,
        playlistAdsRef,
        hasAds,
        refreshPlaylist,
        startPlaylistAsync,
        advancePlayhead,
        on2SecondPlay,
        onAdEnd,
        onError,
        toggleAdFavoriteAsync,
        isPaused,
        isFavorite,
        isAudioMuted,
        setIsAudioMuted,
        setIsPaused,
        setIsFavorite,
        isFullScreen,
        toggleFullScreen,
        videoPlayerRef,
    };
}

export interface IPlaylistViewModel {
    adInterestsEnabled: boolean;
    activeAd: Ad | undefined;
    playlistAdsRef: MutableRefObject<Ad[]>;
    hasAds: () => boolean;
    refreshPlaylist: () => void;
    startPlaylistAsync: () => Promise<void>;
    advancePlayhead: () => void;
    on2SecondPlay: (ad: Ad) => void;
    onAdEnd: (ad: Ad) => Promise<void>;
    onError: (ad: Ad) => Promise<void>;
    toggleAdFavoriteAsync: () => Promise<void>;
    isAudioMuted: boolean;
    isPaused: boolean;
    isFavorite: boolean;
    setIsAudioMuted: (value: boolean) => void;
    setIsPaused: (value: boolean) => void;
    setIsFavorite: (value: boolean) => void;
    isFullScreen: boolean;
    toggleFullScreen: () => void;
    videoPlayerRef: React.RefObject<HTMLVideoElement>;
}
