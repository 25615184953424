import { routeProvider } from './RouteProvider';
import { Playlist } from '../models/Playlist';
import { IPlaylistDtoIn } from '../dtos';
import { httpService } from '../../services';
import { playlistFactory as factory } from '../factories';

class PlaylistProvider {
    async getNewPlaylist(accessToken: string): Promise<Playlist> {
        var route = routeProvider.getPlaylistRoute();
        var playlistDto = await httpService.getAsync<IPlaylistDtoIn>(
            route,
            accessToken
        );
        return factory.fromPlaylistDto(playlistDto);
    }
}

export const playlistProvider = new PlaylistProvider();
