import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties } from 'react';
import colors from '../../../assets/colors.json';

export const SessionEndedIcon = (props: ISessionEndedIconProps) => {
    return (
        <FontAwesomeIcon
            icon={props.icon}
            style={iconStyle}
            color={colors.PrimaryTextButtonBackground}
        />
    );
};

const iconStyle: CSSProperties = {
    fontSize: '70px',
};

interface ISessionEndedIconProps {
    icon: IconProp;
}
