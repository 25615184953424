import { HashiCleanButton } from './HashiCleanButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const defaultIconSize = '20px';
const defaultIconColor = '#000000';
const defaultIconPadding = 10;
const defaultAnimationIterations = 3;

export const HashiIconButton = (props: IHashiIconButtonProps) => {
    const size = props.iconSize ?? defaultIconSize;
    const color = props.iconColor ?? defaultIconColor;
    const padding = props.iconPadding ?? defaultIconPadding;

    let iconStyles: React.CSSProperties = {
        fontSize: size,
        padding: padding,
    };

    const pulseAnimationStyle: React.CSSProperties = {
        boxShadow: '0 0 0 rgba(43, 43, 42, 0.4)',
        animation: 'pulse 1s infinite',
        animationIterationCount:
            props.pulseIterations ?? defaultAnimationIterations,
        borderRadius: '50%',
    };

    if (props.isPulseEnabled) {
        Object.assign(iconStyles, pulseAnimationStyle as any);
    }

    return (
        <HashiCleanButton
            disabled={props.disabled}
            onPress={props.onPress}
            visible={props.visible}
            style={props.style}
        >
            <FontAwesomeIcon
                icon={props.icon}
                style={iconStyles}
                color={color}
            />
        </HashiCleanButton>
    );
};
export interface IHashiIconButtonProps {
    style?: React.CSSProperties;
    icon: IconProp;
    iconSize?: string;
    iconPadding?: number;
    iconColor?: string;
    visible?: boolean;
    onPress?: () => any;
    disabled?: boolean;
    isPulseEnabled?: boolean;
    pulseIterations?: number;
}
