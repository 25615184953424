import { LocalVideoTrack } from 'twilio-video';
import { useCallback, useRef, useState } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';
import useIsTrackEnabled from '../useIsTrackEnabled/useIsTrackEnabled';
import { logger } from '../../../services';

export default function useLocalVideoToggle() {
    const {
        room: { localParticipant },
        localTracks,
        getLocalVideoTrack,
        removeLocalVideoTrack,
        onError,
    } = useVideoContext();
    const videoTrack = localTracks.find((track) =>
        track.name.includes('camera')
    ) as LocalVideoTrack;
    const [isPublishing, setIspublishing] = useState(false);
    const previousDeviceIdRef = useRef<string>();
    const isEnabled = useIsTrackEnabled(videoTrack);

    const toggleVideoEnabledAsync = useCallback(async () => {
        try {
            if (!isPublishing) {
                if (videoTrack) {
                    previousDeviceIdRef.current = videoTrack.mediaStreamTrack.getSettings().deviceId;
                    const localTrackPublication = localParticipant?.unpublishTrack(
                        videoTrack
                    );
                    // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
                    localParticipant?.emit(
                        'trackUnpublished',
                        localTrackPublication
                    );
                    removeLocalVideoTrack();
                } else {
                    setIspublishing(true);
                    const track = await getLocalVideoTrack({
                        deviceId: { exact: previousDeviceIdRef.current },
                    });
                    await localParticipant?.publishTrack(track, {
                        priority: 'low',
                    });
                }
            }
        } catch (ex) {
            logger.logError(ex);
            onError(ex);
        } finally {
            setIspublishing(false);
        }
    }, [
        videoTrack,
        localParticipant,
        getLocalVideoTrack,
        isPublishing,
        onError,
        removeLocalVideoTrack,
    ]);

    return [isEnabled, toggleVideoEnabledAsync] as const;
}
