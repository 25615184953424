import React, { useRef, useState } from 'react';
import styled from 'styled-components';

export const HashiTextInput = (props: IHashiTextInputProps) => {
    const input = useRef<HTMLInputElement>(null);
    const [value, setValue] = useState<string>(props.value ?? '');

    const setFocusToInput = () => {
        input.current?.focus();
    };

    const onChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
        if (props.onChange) {
            props.onChange(event.target.value);
        }
    };

    return (
        <InputContainer>
            <InputLabel onClick={setFocusToInput}>{props.label}</InputLabel>
            <TransparentInput
                ref={input}
                value={value}
                onChange={onChangeEvent}
            />
        </InputContainer>
    );
};

const TransparentInput = styled.input`
    flex: 1;
    --webkit-appearance: none;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    margin-left: 10px;

    @media (max-width: 380px) {
        font-size: 14px;
        line-height: 16px;
    }
`;

const InputLabel = styled.div`
    font-size: 16px;
    line-height: 19px;
    color: rgba(255, 255, 255, 0.64);

    @media (max-width: 380px) {
        font-size: 14px;
        line-height: 16px;
    }
`;

const InputContainer = styled.div`
    height: 44px;
    background: rgba(255, 255, 255, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 12px;
    margin-bottom: 10px;

    input:nth-of-type(1) {
        min-width: 0;
    }

    :focus-within {
        border: 1px solid #ffffff;
    }

    @media (max-width: 380px) {
        height: 40px;
    }
`;

interface IHashiTextInputProps {
    label: string;
    value?: string;
    onChange?: (value: string) => any;
}
