import { useContext } from 'react';
import { PlaylistContext } from './index';

export default function usePlaylistViewModel() {
    const context = useContext(PlaylistContext);
    if (!context) {
        throw new Error(
            'usePlaylistContext must be used within a PlaylistProvider'
        );
    }
    return context;
}
