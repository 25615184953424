import React, { useState } from 'react';
import HashiControlFab from '../../../components/HashiControlFab';
import RoomSettingsModal from '../../../components/RoomSettingsModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/pro-light-svg-icons';
import IHasStyle from '../../../components/propInterfaces/IHasStyle';

export default function Menu(props: IHasStyle) {
    const [settingsOpen, setSettingsOpen] = useState(false);

    return (
        <>
            <HashiControlFab
                style={props.style}
                onClick={() => setSettingsOpen(true)}
            >
                <FontAwesomeIcon icon={faCog} size='2x' />
            </HashiControlFab>
            <RoomSettingsModal
                open={settingsOpen}
                onClose={() => {
                    setSettingsOpen(false);
                }}
            />
        </>
    );
}
