import React, { createContext, useContext, useReducer, useState } from 'react';
import { RoomType } from '../types';
import { TwilioError } from 'twilio-video';
import {
    settingsReducer,
    initialSettings,
    Settings,
    SettingsAction,
} from './settings/settingsReducer';
import useActiveSinkId from './useActiveSinkId/useActiveSinkId';

export interface StateContextType {
    error: TwilioError | null;
    setError(error: TwilioError | null): void;
    user?: null | {
        displayName: undefined;
        photoURL: undefined;
        passcode?: string;
    };
    signIn?(passcode?: string): Promise<void>;
    signOut?(): Promise<void>;
    isAuthReady?: boolean;
    activeSinkId: string;
    setActiveSinkId(sinkId: string): void;
    settings: Settings;
    dispatchSetting: React.Dispatch<SettingsAction>;
    roomType?: RoomType;
}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
    const [error, setError] = useState<TwilioError | null>(null);
    const [activeSinkId, setActiveSinkId] = useActiveSinkId();
    const [settings, dispatchSetting] = useReducer(
        settingsReducer,
        initialSettings
    );

    let contextValue = {
        error,
        setError,
        activeSinkId,
        setActiveSinkId,
        settings,
        dispatchSetting,
    } as StateContextType;

    return (
        <StateContext.Provider value={contextValue}>
            {props.children}
        </StateContext.Provider>
    );
}

export function useAppState() {
    const context = useContext(StateContext);
    if (!context) {
        throw new Error('useAppState must be used within the AppStateProvider');
    }
    return context;
}
