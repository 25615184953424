enum httpRequestMethods {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    DELETE = 'DELETE',
    PUT = 'PUT',
}

class HttpService {
    public async getAsync<T>(
        url: URL | string,
        accessToken?: string,
        headers?: IHttpHeaders
    ): Promise<T> {
        let request = this.getRequestObject(
            httpRequestMethods.GET,
            accessToken,
            headers
        );
        return await this.fetchAsync(url, request);
    }

    public async postAsync<T>(
        url: URL | string,
        data?: object,
        accessToken?: string,
        metadata?: object,
        headers?: IHttpHeaders
    ): Promise<T> {
        let request = this.getRequestObject(
            httpRequestMethods.POST,
            accessToken,
            headers,
            data,
            metadata
        );
        return await this.fetchAsync(url, request);
    }

    public async patchAsync<T>(
        url: URL | string,
        data: object,
        accessToken: string,
        headers?: IHttpHeaders
    ): Promise<T> {
        let request = this.getRequestObject(
            httpRequestMethods.PATCH,
            accessToken,
            headers,
            data
        );
        return await this.fetchAsync(url, request);
    }

    public async putAsync<T>(
        url: URL | string,
        data: object,
        accessToken: string,
        headers?: IHttpHeaders
    ): Promise<T> {
        let request = this.getRequestObject(
            httpRequestMethods.PUT,
            accessToken,
            headers,
            data
        );
        return await this.fetchAsync(url, request);
    }

    public async deleteAsync(
        url: URL | string,
        accessToken: string,
        headers?: IHttpHeaders
    ): Promise<void> {
        let request = this.getRequestObject(
            httpRequestMethods.DELETE,
            accessToken,
            headers
        );
        return await this.fetchAsync(url, request);
    }

    private async fetchAsync<T>(
        uri: URL | string,
        request: RequestInit
    ): Promise<T> {
        let response = await fetch(uri.toString(), request);

        if (!response.ok) {
            throw new HttpError(response);
        }

        return response.text().then((text) => {
            return text ? JSON.parse(text) : {};
        });
    }

    private getRequestObject(
        httpRequestMethod: httpRequestMethods,
        authToken?: string,
        headers?: IHttpHeaders,
        data?: any,
        metadata?: any
    ): RequestInit {
        let request = {} as RequestInit;
        request.method = httpRequestMethod;

        headers = headers ?? ({} as IHttpHeaders);

        if (!headers['Content-Type']) {
            headers['Content-Type'] = 'application/json';
        }

        if (!headers.Accept) {
            headers['Accept'] = 'application/json';
        }

        if (authToken) {
            headers['Authorization'] = `Bearer ${authToken}`;
        }

        request.headers = headers as any;

        let body;

        if (data) {
            if (metadata) {
                headers['X-Metadata'] = JSON.stringify(metadata);
            }
            if (data['size']) {
                headers['Content-Length'] = data['size'];
                body = data as Blob;
            } else {
                body = JSON.stringify(data);
            }
            request.body = body;
        }

        return request;
    }
}

export const httpService = new HttpService();

export type HttpErrorStatus = 'ClientError' | 'ServerError';
export type HttpStatus =
    | 'Informational'
    | 'Success'
    | 'Redirection'
    | HttpErrorStatus;
export interface HttpErrorBodyDTOin {
    Message?: string;
    Data?: any;
}
export class HttpError extends Error {
    public statusType?: HttpErrorStatus;
    constructor(public response: Response) {
        super('Response contained error');

        const status = response.status;
        if (!status) return;
        if (status >= 400 && status < 500) {
            this.statusType = 'ClientError';
        } else if (status >= 500 && status < 600) {
            this.statusType = 'ServerError';
        }
    }

    public async bodyAsync(): Promise<HttpErrorBodyDTOin> {
        return this.response.json();
    }
}

interface IHttpHeaders {
    'Content-Type':
        | 'application/json'
        | 'application/octet-stream'
        | 'image/jpeg'
        | 'video/mp4';
    Accept:
        | 'application/json'
        | 'application/octet-stream'
        | 'image/jpeg'
        | 'video/mp4';
    Authorization: string;
    'Content-Length': string;
    'X-Metadata': string;
}
