import React from 'react';
import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CenterContentContainer } from './CenterContentContainer';
import styled from 'styled-components';

export const PlaylistLoader = () => {
    return (
        <LoaderContainer>
            <CenterContentContainer>
                <FontAwesomeIcon
                    className={'fa-pulse'}
                    icon={faSpinner}
                    color={'white'}
                />
                <LoadingText>Loading...</LoadingText>
            </CenterContentContainer>
        </LoaderContainer>
    );
};

const LoaderContainer = styled.div`
    background-color: black;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

const LoadingText = styled.div`
    margin-top: 10px;
    font-size: 16px;
    color: white;
`;
