import styled from 'styled-components';
import React, { CSSProperties, useEffect } from 'react';
import useVideoContext from '../../twilio/hooks/useVideoContext/useVideoContext';
import LocalVideoPreview from '../../twilio/components/PreJoinScreens/DeviceSelectionScreen/LocalVideoPreview/LocalVideoPreview';
import LocalAudioLevelIndicator from '../../twilio/components/LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import { useRootNavigation } from '../../NavigationControllers/useRootNavigation';
import colors from '../../assets/colors.json';
import { Strings } from '../../assets/strings';
import { HashiFlexAbsoluteCenterContainer } from '../../components';
import { TeethCheckToolBar } from './components/TeethCheckToolBar';
import { HashiAsyncButton } from '../../components/HashiAsyncButton';
import { logger } from '../../services';
import { useAppViewModel } from '../../ViewModels/AppViewModelArea/useAppViewModel';
import { usePauseTracks } from '../../hooks/usePauseTracks';

export function TeethCheckView() {
    const { jwt, audioPlayerRef, isRecordingEnabled } = useAppViewModel();
    const {
        connect,
        getAudioAndVideoTracks,
        isAcquiringLocalTracks,
        isConnecting,
    } = useVideoContext();
    const { goToRoom, goToWelcome } = useRootNavigation();
    const disableButtons = isAcquiringLocalTracks || isConnecting;
    const { pauseTracksAsync } = usePauseTracks();

    useEffect(() => {
        try {
            getAudioAndVideoTracks();
        } catch (ex) {
            alert(Strings.DeviceErrorOccured);
        }
    }, []);

    const onContinue = async () => {
        //This allows us to play audio anywhere after this!
        //Don't worry this audio player has no source.
        audioPlayerRef?.current?.play();

        if (!jwt) {
            alert(Strings.UnableToFindRoom);
            logger.logMessage('Missing jwt for twilio');
            await pauseTracksAsync();
            goToWelcome();
            return;
        }

        try {
            await pauseTracksAsync();
            await connect(jwt);
            goToRoom();
        } catch (ex) {
            logger.logError(ex);
            alert(Strings.UnknownErrorOccured);
        }
    };

    return (
        <HashiFlexAbsoluteCenterContainer>
            <TeethCheckViewContainer>
                <ContentConatainer>
                    <VideoContainer>
                        <LocalVideoPreview identity={'you'} />
                        <TopBarContainer>
                            <AudioLevelContainer>
                                <LocalAudioLevelIndicator />
                            </AudioLevelContainer>
                        </TopBarContainer>
                        <TeethCheckToolBar isButtonsDisabled={disableButtons} />
                    </VideoContainer>

                    <BelowVideoContainer>
                        {isRecordingEnabled ? (
                            <ByUsingThisServiceText>
                                {Strings.ByUsingThisService}
                            </ByUsingThisServiceText>
                        ) : (
                            <HowDoYouLookTitle />
                        )}
                        <HashiAsyncButton
                            ContainerStyle={ButtonContainerStyle}
                            onPress={onContinue}
                        >
                            {Strings.Start}
                        </HashiAsyncButton>
                    </BelowVideoContainer>
                    {isRecordingEnabled && <HowDoYouLookTitle />}
                    <FrontFacingCameraText>
                        {Strings.UseFrontCamera}
                    </FrontFacingCameraText>
                </ContentConatainer>
            </TeethCheckViewContainer>
        </HashiFlexAbsoluteCenterContainer>
    );
}

const ButtonContainerStyle: CSSProperties = {
    margin: 'auto',
};

const BelowVideoContainer = styled.div`
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
`;

const TeethCheckViewContainer = styled.div`
    width: 100%;
    margin: auto;
    overflow: auto;
`;

const ContentConatainer = styled.div`
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    max-width: 800px;
    min-width: 250px;
    margin: auto;

    @media (max-height: 500px) {
        max-width: 60%;
    }
`;

const HowDoYouLookTitle = () => {
    return <HowDoYouLookText>{Strings.HowDoYouLook}</HowDoYouLookText>;
};

const HowDoYouLookText = styled.div`
    flex: 1;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    min-width: 200px;
    color: ${colors.PrimaryTitleText};
`;

const ByUsingThisServiceText = styled.div`
    flex: 1;
    margin-right: 10px;
    font-size: 11px;
    line-height: 16px;
    color: ${colors.SecondaryText};
`;

const FrontFacingCameraText = styled.div`
    margin-top: 10px;
    font-size: 18px;
    line-height: 23px;
    color: ${colors.SecondaryText};

    @media (min-width: 450px) {
        max-width: 50%;
    }
`;

const VideoContainer = styled.div`
    position: relative;
    height: 60vh;
`;

const TopBarContainer = styled.div`
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
`;

const AudioLevelContainer = styled.div`
    background-color: ${colors.PrimaryVideoOverlay};
    border-radius: 16px 0 8px 0;
    padding: 3px;
    display: inline-flex;
`;
