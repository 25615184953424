import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { HashiIconButton, IHashiIconButtonProps } from './HashiIconButton';
import { useEffect, useState } from 'react';

export const HashiIconToggleButton = (props: IHashiIconToggleButton) => {
    const [isSelected, setIsSelected] = useState<boolean>(!!props.selected);

    useEffect(() => {
        if (props.selected === isSelected) {
            return;
        }
        setIsSelected(!!props.selected);
    }, [props.selected, isSelected]);

    const icon: IconProp = isSelected
        ? //Some weird typscript bug is forcing me to cast one of these icons to any to stop the compiler form erroring.
          (props.selectedIcon as any)
        : props.icon;

    const color = isSelected
        ? props.selectedIconColor ?? props.iconColor
        : props.iconColor;

    const onPress = () => {
        if (props.toggleOnTap) {
            setIsSelected(!isSelected);
        }
        if (props.onPress) {
            props.onPress();
        }
        if (props.unSelectOnPress && !isSelected) {
            props.unSelectOnPress();
        }
        if (props.selectedOnPress && isSelected) {
            props.selectedOnPress();
        }
    };

    return (
        <HashiIconButton
            style={props.style}
            icon={icon}
            iconColor={color}
            iconPadding={props.iconPadding}
            iconSize={props.iconSize}
            disabled={props.disabled}
            visible={props.visible}
            onPress={onPress}
        />
    );
};

interface IHashiIconToggleButton extends IHashiIconButtonProps {
    selected?: boolean;
    selectedIcon: IconProp;
    selectedIconColor?: string;
    toggleOnTap?: boolean;
    selectedOnPress?: () => void;
    unSelectOnPress?: () => void;
}
