import styled from 'styled-components';

export const HashiCleanButton = (props: IHashiButtonProps) => {
    const showButton = props.visible ?? true;

    const onPress = () => {
        if (props.disabled) {
            return;
        }
        if (props.onPress) {
            props.onPress();
        }
    };
    return (
        <ButtonWrapper>
            {showButton && (
                <Button onClick={onPress} style={props.style}>
                    {props.children}
                </Button>
            )}
        </ButtonWrapper>
    );
};

const ButtonWrapper = styled.div`
    cursor: pointer;
`;
const Button = styled.div`
    :hover {
        filter: brightness(90%);
    }

    :active {
        filter: brightness(70%);
    }
`;

export interface IHashiButtonProps {
    style?: React.CSSProperties;
    visible?: boolean;
    children: JSX.Element;
    onPress?: () => any;
    disabled?: boolean;
}
