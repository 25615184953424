class LoggerService {

    public logError(error: Error) {
        console.log(error)
    }

    public logMessage(message: string) {
        console.log(message);
    }
}

export const logger = new LoggerService();