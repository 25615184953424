import { useToggleTracksEnabled } from './useToggleTracksEnabled';
import useVideoContext from '../twilio/hooks/useVideoContext/useVideoContext';

export const usePauseTracks = () => {
    const tracksEnabledService = useToggleTracksEnabled();
    const { isLocalAudioPausedRef, isLocalVideoPausedRef } = useVideoContext();

    const playTracksAsync = async () => {
        if (isLocalAudioPausedRef.current && isLocalVideoPausedRef.current) {
            await tracksEnabledService.enableAllTracksAsync();
            isLocalAudioPausedRef.current = false;
            isLocalVideoPausedRef.current = false;
            return;
        }
        if (isLocalVideoPausedRef.current) {
            await tracksEnabledService.enableVideoAsync();
            isLocalVideoPausedRef.current = false;
        }
        if (isLocalAudioPausedRef.current) {
            await tracksEnabledService.enableAudioAsync();
            isLocalAudioPausedRef.current = false;
        }
    };

    const pauseTracksAsync = async () => {
        if (tracksEnabledService.isAudioEnabled) {
            isLocalAudioPausedRef.current = true;
        }
        if (tracksEnabledService.isVideoEnabled) {
            isLocalVideoPausedRef.current = true;
        }
        await tracksEnabledService.disableAllTracksAsync();
    };

    return { pauseTracksAsync, playTracksAsync };
};
