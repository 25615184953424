import { faRepeatAlt } from '@fortawesome/pro-light-svg-icons';
import React from 'react';
import styled from 'styled-components';
import { LocalVideoTrack } from 'twilio-video';
import colors from '../../../assets/colors.json';
import { HashiIconButton } from '../../../components/HashiIconButton';
import {
    DEFAULT_VIDEO_CONSTRAINTS,
    SELECTED_VIDEO_INPUT_KEY,
} from '../../../constants';
import { useVideoInputDevices } from '../../../twilio/hooks/deviceHooks/deviceHooks';
import useMediaStreamTrack from '../../../twilio/hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../twilio/hooks/useVideoContext/useVideoContext';
import SettingsMenuButton from '../components/SettingsMenuButton';
import { ToggleVideoButton } from '../../../components/ToggleVideoButton';
import { ToggleAudioButton } from '../../../components/ToggleAudioButton';

const iconSize = '20px';
const iconColor = colors.PrimaryTitleText;

export const TeethCheckToolBar = (props: ITeethCheckToolBarProps) => {
    const { localTracks } = useVideoContext();
    const devices = useVideoInputDevices();

    const ToggleCameraButton = () => {
        const localVideoTrack = localTracks.find(
            (track) => track.kind === 'video'
        ) as LocalVideoTrack;
        const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);

        const toggleCamera = () => {
            const selectedDeviceId = mediaStreamTrack?.getSettings().deviceId;
            const deviceIndex = devices.findIndex(
                (x) => x.deviceId === selectedDeviceId
            );
            const newIndex = deviceIndex < devices.length ? deviceIndex + 1 : 0;
            const newDevice = devices[newIndex] ?? devices[0];
            window.localStorage.setItem(
                SELECTED_VIDEO_INPUT_KEY,
                newDevice.deviceId
            );
            localVideoTrack.restart({
                ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
                deviceId: { exact: newDevice.deviceId },
            });
        };

        return devices.length > 1 ? (
            <HashiIconButton
                icon={faRepeatAlt}
                iconSize={iconSize}
                disabled={props.isButtonsDisabled}
                iconColor={iconColor}
                onPress={toggleCamera}
            />
        ) : (
            <></>
        );
    };

    return (
        <ToolBarContainer>
            <ToolBarIconContainer>
                <IconContainer>
                    <ToggleAudioButton
                        iconSize={iconSize}
                        iconColor={iconColor}
                        isDisabled={props.isButtonsDisabled}
                    />
                </IconContainer>
                <IconContainer>
                    <ToggleVideoButton
                        iconSize={iconSize}
                        iconColor={iconColor}
                        isDisabled={props.isButtonsDisabled}
                    />
                </IconContainer>
                <ToggleCameraButton />
                <SettingsMenuButton />
            </ToolBarIconContainer>
        </ToolBarContainer>
    );
};

const ToolBarContainer = styled.div`
    z-index: 2;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 51px;
    background-color: ${colors.PrimaryVideoOverlay};
    padding: 0 40px;
    border-radius: 0 0 16px 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const ToolBarIconContainer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const IconContainer = styled.div`
    display: flex;
    width: 45px;
    justify-content: center;
`;

interface ITeethCheckToolBarProps {
    isButtonsDisabled: boolean;
}
