import React, { CSSProperties, useState } from 'react';
import { HashiSpinner } from './HashiSpinner';
import styled from 'styled-components';
import IHasChildren from './propInterfaces/IHasChildren';
import { HashiFilledBorderButton } from './HashiBorderButton';
import { useIsMounted } from '../hooks/useIsMounted';

var buttonHeight: number | undefined;
var buttonWidth: number | undefined;
var spinnerSize: number | undefined;
var fontSize = 16;

export const HashiAsyncButton = (props: IHashiAsyncTextButton) => {
    fontSize = props.fontSize ?? fontSize;
    const [isLoading, setIsLoading] = useState<boolean>();
    const isMounted = useIsMounted();

    const loadDataAsync = async () => {
        setIsLoading(true);
        await props.onPress();
        if (isMounted.current) {
            setIsLoading(false);
        }
    };

    const setLoadingSize = (element: HTMLDivElement | null) => {
        spinnerSize = fontSize + 3;
        //We need to wait so everything is layed out before we get the sizes
        setTimeout(() => {
            buttonHeight = element?.clientHeight;
            buttonWidth = element?.clientWidth;
        }, 100);
    };

    return (
        <HashiFilledBorderButton
            ContainerStyle={props.ContainerStyle}
            onPress={loadDataAsync}
        >
            <Container
                ref={setLoadingSize}
                style={{ height: buttonHeight, width: buttonWidth }}
            >
                {isLoading ? (
                    <LoadingContainer>
                        <HashiSpinner size={spinnerSize} />
                    </LoadingContainer>
                ) : (
                    props.children
                )}
            </Container>
        </HashiFilledBorderButton>
    );
};

const Container = styled.div``;

const LoadingContainer = styled.div`
    text-align: center;
`;

interface IHashiAsyncTextButton extends IHasChildren {
    onPress: () => Promise<any>;
    fontSize?: number;
    ContainerStyle?: CSSProperties;
}
