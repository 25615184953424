import { routeProvider } from './RouteProvider';
import { httpService, logger } from '../../services';
import { IAdInterestDtoIn } from '../dtos';
import { Ad } from '../models/Ad';

class AdInterestsProvider {
    public async deleteAdInterestAsync(
        adInterestId: number,
        accessToken: string
    ): Promise<void> {
        var uri = routeProvider.deletePatientAdInterestRoute(adInterestId);
        await httpService.deleteAsync(uri, accessToken);
    }

    public async saveAdInterestAsync(ad: Ad, accessToken: string) {
        var uri = routeProvider.createAdInterestRoute(ad);

        try {
            var interest = await httpService.postAsync<IAdInterestDtoIn>(
                uri,
                undefined,
                accessToken
            );
            ad.AdInterestId = interest.Id;
        } catch (ex) {
            logger.logError(ex);
            //nothing to do here.
        }
    }
}

export const adInterestsProvider = new AdInterestsProvider();
