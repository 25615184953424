import { Strings } from '../assets/strings';
import { httpService } from '../services/HttpService';
import { IAppViewModelContext } from '../ViewModels/AppViewModelArea';

const localStorageKey = 'room';
const getRoomRoute = 'api/Rooms/RoomParticipantAccess';
class RoomProvider implements IRoomProvider {
    public async getRoomTokenFromParticipantCode(
        roomCode: string
    ): Promise<IRoomDetailsDtoIn> {
        return await httpService.postAsync<IRoomDetailsDtoIn>(
            getRoomRoute,
            undefined,
            roomCode
        );
    }

    public async loadRoomDetailsAsync(
        roomCode: string | undefined,
        appViewModel: IAppViewModelContext
    ) {
        if (!roomCode || roomCode.length < 9) {
            throw new Error(Strings.RoomCodeRequired);
        }

        let roomDetails = await this.getRoomTokenFromParticipantCode(roomCode);

        if (!roomDetails) {
            appViewModel.setRoomCode(undefined);
            throw new Error(Strings.UnableToFindRoom);
        }
        localStorage.setItem(localStorageKey, roomCode);

        appViewModel.setCompanyName(roomDetails.CompanyName);
        appViewModel.setCustomerFirstName(roomDetails.CustomerFirstName);
        appViewModel.setCustomerLastName(roomDetails.CustomerLastName);
        appViewModel.setJwt(roomDetails.Jwt);
        appViewModel.setIsRecordingEnabled(roomDetails.IsRecordingEnabled);
    }

    public GetLocalStorageRoom() {
        return localStorage.getItem(localStorageKey);
    }
}

interface IRoomProvider {
    getRoomTokenFromParticipantCode(
        roomCode: string
    ): Promise<IRoomDetailsDtoIn>;
    loadRoomDetailsAsync(
        roomCode: string,
        appViewModel: IAppViewModelContext
    ): Promise<void>;
}
interface IRoomDetailsDtoIn {
    Jwt: string;
    CompanyName: string;
    CustomerFirstName: string;
    CustomerLastName: string;
    IsRecordingEnabled: boolean;
}

export const roomProvider = new RoomProvider();
