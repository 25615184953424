import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import colors from '../assets/colors.json';
import { HashiFlexAbsoluteCenterContainer } from '../components/HashiContentContainers';
import { Strings } from '../assets/strings';
import { PlaylistModule } from '../Playlist/PlaylistModule';
import { useAppViewModel } from '../ViewModels/AppViewModelArea/useAppViewModel';
import { usePauseTracks } from '../hooks/usePauseTracks';

export const WaitingRoomView = () => {
    const { roomCode } = useAppViewModel();
    const [hasPlaylist, setHasPlaylist] = useState(true);
    const { pauseTracksAsync } = usePauseTracks();

    useEffect(() => {
        pauseTracksAsync();
    }, []);

    const onDisablePlaylist = () => {
        setHasPlaylist(false);
    };

    return (
        <HashiFlexAbsoluteCenterContainer>
            <WaitingRoomContainer>
                <ContentContainer>
                    <WelcomeSideBySideWrap>
                        {hasPlaylist && (
                            <PlaylistContainer>
                                <PlaylistModule
                                    accessToken={roomCode!}
                                    onDisablePlaylist={onDisablePlaylist}
                                />
                            </PlaylistContainer>
                        )}
                        <TextContainer>
                            <HashiFlexAbsoluteCenterContainer>
                                <WelcomeTextContainer>
                                    <GettingReadyText />
                                    <PleaseWaitText />
                                    <LineBreak />
                                    {hasPlaylist && <HowToFavoriteText />}
                                </WelcomeTextContainer>
                            </HashiFlexAbsoluteCenterContainer>
                        </TextContainer>
                    </WelcomeSideBySideWrap>
                </ContentContainer>
            </WaitingRoomContainer>
        </HashiFlexAbsoluteCenterContainer>
    );
};

const WaitingRoomContainer = styled.div`
    width: 100%;
    margin: auto;
    overflow: auto;
`;

const ContentContainer = styled.div`
    box-sizing: border-box;
    width: 100%;
    min-width: 250px;
    margin: auto;
`;

const WelcomeSideBySideWrap = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
`;

const PlaylistContainer = styled.div`
    width: 100%;
    margin: auto;
    max-width: 50%;
    @media (orientation: portrait) {
        max-width: 100%;
    }
    @media (orientation: landscape) {
        max-width: 50%;
    }
`;

const TextContainer = styled.div`
    width: 100%;
    max-width: 50%;
    @media (orientation: portrait) {
        max-width: 100%;
    }
    @media (orientation: landscape) {
        max-width: 50%;
    }
`;

const WelcomeTextContainer = styled.div`
    padding: 20px;
    text-align: left;
    flex-direction: row;
`;

const ReadyText = styled.div`
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    color: ${colors.PrimaryTitleText};

    @media (max-width: 380px) {
        font-size: 18px;
        line-height: 22px;
    }
`;
const GettingReadyText = () => {
    return <ReadyText>{Strings.GettingThingsReady}</ReadyText>;
};

const WaitText = styled.div`
    margin-top: 10px;
    font-size: 18px;
    line-height: 23px;
    color: ${colors.SecondaryText};

    @media (max-width: 380px) {
        font-size: 16px;
    }
`;
const PleaseWaitText = () => {
    return <WaitText>{Strings.PleaseWait}</WaitText>;
};

const LineBreak = styled.div`
    margin: 40px 0 20px 0;
    border: 1px solid rgba(255, 255, 255, 0.2);
`;

const HowText = styled.div`
    font-size: 14px;
    line-height: 18px;
    color: ${colors.PrimaryTitleText};
`;
const HowToFavoriteText = () => {
    return <HowText>{Strings.HowToHeart}</HowText>;
};
