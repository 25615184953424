import { faExclamationTriangle } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { Strings } from '../assets/strings';

export const HashiBrowserNotSupportedBanner = () => {
    return (
        <BannerContainer>
            <FontAwesomeIcon
                icon={faExclamationTriangle}
                fontSize={18}
                color={'white'}
                style={{ padding: 20 }}
            />
            <BannerText>{Strings.BrowserUnsupported}</BannerText>
        </BannerContainer>
    );
};

const BannerContainer = styled.div`
    display: flex;
    width: 100%;
    height: 65px;
    background-color: #ff2719;
    align-items: center;
    justify-content: center;
`;

const BannerText = styled.div`
    font-size: 14px;
    color: #ffffff;
`;
