import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { IRoute } from '../NavigationControllers/dtos';

//TODO: This should Probably be a Not Found/Error screen so Developer know whats happneing
const fallbackRoute = '/';

export const useHashiRouting = (routes: IRoute[]) => {
    const history = useHistory();
    const location = useLocation();

    const showComponent = (component: () => JSX.Element) => {
        const route = getRoute(component);
        if (route === location.pathname) {
            return;
        }
        history.replace(route);
    };

    const getRoute = (component: () => JSX.Element) => {
        return (
            routes.find((x) => x.component.name === component.name)?.path ??
            fallbackRoute
        );
    };

    return showComponent;
};
