import React, { useEffect, useState } from 'react';
import Controls from './components/Controls';
import ReconnectingNotification from '../../twilio/components/ReconnectingNotification/ReconnectingNotification';
import Room from '../../twilio/components/Room/Room';
import { usePauseTracks } from '../../hooks/usePauseTracks';
import styled from 'styled-components';

const showControlsTime = 4000;
var showControlsTimeout: NodeJS.Timeout;

export default function RoomView() {
    const [showControls, setShowControls] = useState(true);
    const { playTracksAsync } = usePauseTracks();

    useEffect(() => {
        loadDataAsync();
    }, []);

    const loadDataAsync = async () => {
        await playTracksAsync();
        ShowControls();
    };

    const ShowControls = () => {
        clearTimeout(showControlsTimeout);
        setShowControls(true);
        showControlsTimeout = setTimeout(() => {
            setShowControls(false);
        }, showControlsTime);
    };

    return (
        <RoomContainer onClick={ShowControls}>
            <MainContainer>
                <ReconnectingNotification />
                <Room />
                <Controls isVisible={showControls} />
            </MainContainer>
        </RoomContainer>
    );
}

const RoomContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
`;

const MainContainer = styled.div`
    overflow: hidden;
    background-color: black;
    height: 100%;
    width: 100%;
`;
