import { httpService, logger } from '../../services';
import { IAdEventDtoOut } from '../dtos';
import { routeProvider } from './RouteProvider';
import { Ad } from '../models/Ad';
import { AdEventType } from '../models/AdEventType';

class AdEventProvider {
    public postPlayForTwoSecondsAsync = async (ad: Ad, accessToken: string) => {
        await this.postAdEventAsync(AdEventType.TwoSecondView, accessToken, ad);
    };

    public postCompletedAsync = async (ad: Ad, accessToken: string) => {
        await this.postAdEventAsync(AdEventType.FullView, accessToken, ad);
    };

    public postFailedAsync = async (ad: Ad, accessToken: string) => {
        await this.postAdEventAsync(AdEventType.FailedToPlay, accessToken, ad);
    };

    private postAdEventAsync = async (
        type: AdEventType,
        accessToken: string,
        ad?: Ad
    ) => {
        if (!ad) {
            return;
        }
        let event = this.getAdEventDto(ad.id, type);
        await this.postAsync(accessToken, event);
    };

    private async postAsync(
        accessToken: string,
        dtoOut: IAdEventDtoOut
    ): Promise<void> {
        var uri = routeProvider.adEventsRoute(dtoOut.AdId);

        try {
            await httpService.postAsync(uri, dtoOut, accessToken);
        } catch (ex) {
            logger.logError(ex);
            //Do nothing. We just want to try to post add event.
            //Since we are streaming this shouldn't fail often.
        }
    }

    private getAdEventDto = (adId: number, adEventType: AdEventType) => {
        return {
            AdId: adId,
            EventType: adEventType,
            EventDate: new Date(),
        } as IAdEventDtoOut;
    };
}

export const adEventProvider = new AdEventProvider();
