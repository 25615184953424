import React from 'react';

import AudioInputList from '../twilio/components/DeviceSelectionDialog/AudioInputList/AudioInputList';
import AudioOutputList from '../twilio/components/DeviceSelectionDialog/AudioOutputList/AudioOutputList';
import {
    DialogContent,
    Typography,
    Divider,
    Dialog,
    DialogActions,
    Theme,
    DialogTitle,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VideoInputList from '../twilio/components/DeviceSelectionDialog/VideoInputList/VideoInputList';
import { HashiFilledBorderButton } from './HashiBorderButton';
import { Strings } from '../assets/strings';
import styled from 'styled-components';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        flex: 1,
        minWidth: '400px',
        minHeight: '400px',
        '& .inputSelect': {
            flex: 1,
        },
    },
    headline: {
        marginBottom: '1.3em',
        fontSize: '1.1rem',
    },
}));

export default function RoomSettingsModal(props: IRoomSettingsModalProps) {
    const classes = useStyles();

    return (
        <Dialog open={props.open} onClose={props.onClose}>
            <DialogTitle>{Strings.AudioVideoSettings}</DialogTitle>
            <Divider />
            <DialogContent className={classes.container}>
                <DropdownContainer>
                    <Typography variant='h6' className={classes.headline}>
                        {Strings.Video}
                    </Typography>
                    <VideoInputList />
                </DropdownContainer>
                <Divider />
                <DropdownContainer>
                    <Typography variant='h6' className={classes.headline}>
                        {Strings.Audio}
                    </Typography>
                    <AudioInputList />
                </DropdownContainer>
                <DropdownContainer>
                    <AudioOutputList />
                </DropdownContainer>
            </DialogContent>
            <Divider />
            <DialogActions>
                <HashiFilledBorderButton onPress={props.onClose}>
                    {Strings.Done}
                </HashiFilledBorderButton>
            </DialogActions>
        </Dialog>
    );
}

const DropdownContainer = styled.div`
    margin: 2em 0 0.8em;
    &:first-child {
        margin: 1em 0 2em 0;
    }
`;

interface IRoomSettingsModalProps {
    open: boolean;
    onClose: () => void;
}
