import { createContext, ReactNode, useEffect, useRef } from 'react';
import {
    IAppViewModelValue,
    useMakeAppViewModelValue,
} from './useMakeAppViewModelValue';
import useQuery from '../../hooks/useQuery';
import { useRootNavigation } from '../../NavigationControllers/useRootNavigation';
import { roomProvider } from '../../Provider/RoomProvider';
import { logger } from '../../services';

export const AppViewModelContext = createContext<IAppViewModelContext>(null!);

export const AppViewModelArea = (props: IAppViewModelAreaProps) => {
    const appViewModelValue: IAppViewModelValue = useMakeAppViewModelValue();
    const navigation = useRootNavigation();
    const roomCode = useQuery('r') ?? roomProvider.GetLocalStorageRoom();
    const audioPlayerRef = useRef<HTMLAudioElement>(null);

    useEffect(() => {
        if (!roomCode) {
            navigation.goToWelcome();
            return;
        }

        appViewModelValue.setRoomCode(roomCode);

        loadRoom();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [appViewModelValue.roomCode]);

    const loadRoom = async () => {
        if (!roomCode) {
            return;
        }
        try {
            await roomProvider.loadRoomDetailsAsync(
                roomCode!,
                appViewModelValue
            );
            setTimeout(() => {
                //Show Loader minimum of 2 seconds
                navigation.goToQuickWelcome();
            }, 2000);
        } catch (ex) {
            //If we fail to load the room just take them to the welcome screen
            localStorage.clear();
            navigation.goToWelcome();
            logger.logError(ex);
        }
    };

    return (
        <>
            <AppViewModelContext.Provider
                value={{ ...appViewModelValue, audioPlayerRef }}
            >
                {props.children}
            </AppViewModelContext.Provider>
            {/* This Audio Tag is Needed here so we can play sounds whenever we want. */}
            <audio ref={audioPlayerRef} />
        </>
    );
};

export interface IAppViewModelContext extends IAppViewModelValue {
    audioPlayerRef?: React.RefObject<HTMLAudioElement>;
}

interface IAppViewModelAreaProps {
    children: ReactNode;
}
