import React from 'react';
import styled from 'styled-components';
import { CenterContentContainer } from '../components/CenterContentContainer';

export const PlaylistError = () => {
    return (
        <FillHorizontal>
            <CenterContentContainer>
                <Text>An error has occured</Text>
            </CenterContentContainer>
        </FillHorizontal>
    );
};

const FillHorizontal = styled.div`
    background-color: black;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    flex: 1;
`;
const Text = styled.div`
    color: white;
`;
