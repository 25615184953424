import { useState, useEffect } from 'react';
import { useIsMounted } from '../../../hooks/useIsMounted';

export function useDevices() {
    const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
    const isMounted = useIsMounted();

    useEffect(() => {
        const getDevices = () =>
            navigator.mediaDevices?.enumerateDevices().then((devices) => {
                if (isMounted.current) {
                    setDevices(devices);
                }
            });
        navigator.mediaDevices?.addEventListener('devicechange', getDevices);
        getDevices();

        return () => {
            navigator.mediaDevices?.removeEventListener(
                'devicechange',
                getDevices
            );
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return devices;
}

export function useAudioInputDevices() {
    const devices = useDevices();
    return devices.filter((device) => device.kind === 'audioinput');
}

export function useVideoInputDevices() {
    const devices = useDevices();
    return devices.filter((device) => device.kind === 'videoinput');
}

export function useAudioOutputDevices() {
    const devices = useDevices();
    return devices.filter((device) => device.kind === 'audiooutput');
}

export function useHasAudioInputDevices() {
    const audioDevices = useAudioInputDevices();
    return audioDevices.length > 0;
}

export function useHasVideoInputDevices() {
    const videoDevices = useVideoInputDevices();
    return videoDevices.length > 0;
}
