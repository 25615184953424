import { VideoAd } from './../models/VideoAd';
import { ImageAd } from './../models/ImageAd';
import { IPlaylistDtoIn, IAdDtoIn } from '../dtos';
import { Playlist } from '../models/Playlist';
import { Ad } from '../models/Ad';
import { AdType } from '../models/AdType';

class PlaylistFactory {
    public fromPlaylistDto(dto: IPlaylistDtoIn): Playlist {
        var playlist = new Playlist();

        if (dto == null) {
            return playlist;
        }

        playlist.adInterestsEnabled = dto.AdInterestsEnabled;

        var ads = dto.Promos?.map(this.fromAdDto).filter((x) => x) as Ad[];

        playlist.ads = ads;

        return playlist;
    }

    private fromAdDto(dto: IAdDtoIn): Ad | null {
        if (dto?.PublicUri == null) {
            return null;
        }
        const isImage =
            dto.Type === AdType.Image ||
            dto.Type === AdType.ImageLandscape ||
            dto.Type === AdType.ImagePortrait;

        return isImage
            ? new ImageAd(
                  dto.Id,
                  dto.Name,
                  dto.Type,
                  dto.PublicUri,
                  dto.Duration,
                  dto.ThumbnailPublicUri,
                  dto.UniqueId
              )
            : new VideoAd(
                  dto.Id,
                  dto.Name,
                  dto.Type,
                  dto.PublicUri,
                  dto.Duration,
                  dto.ThumbnailPublicUri,
                  dto.UniqueId
              );
    }
}

export const playlistFactory = new PlaylistFactory();
