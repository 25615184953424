import React, { useCallback, useRef } from 'react';
import {
    faMicrophoneSlash,
    faMicrophone,
} from '@fortawesome/pro-light-svg-icons';
import { useToggleTracksEnabled } from '../hooks/useToggleTracksEnabled';
import { HashiIconToggleButton } from './HashiIconToggleButton';
import HashiControlFab from './HashiControlFab';

export const ToggleAudioButton = (props: IToggleAudioButtonProps) => {
    const {
        isAudioEnabled,
        enableAudioAsync,
        disableAudioAsync,
    } = useToggleTracksEnabled();
    const lastClickTimeRef = useRef(0);

    const onPress = useCallback(() => {
        if (Date.now() - lastClickTimeRef.current > 500) {
            lastClickTimeRef.current = Date.now();
            if (isAudioEnabled) {
                disableAudioAsync();
            } else {
                enableAudioAsync();
            }
        }
    }, [isAudioEnabled, disableAudioAsync, enableAudioAsync]);

    const AudioIconButton = (iconProps: IAudioIconButtonProps) => {
        return (
            <HashiIconToggleButton
                toggleOnTap={false}
                icon={faMicrophone}
                selectedIcon={faMicrophoneSlash}
                iconSize={props.iconSize}
                iconColor={props.iconColor}
                selected={!isAudioEnabled}
                disabled={props.isDisabled}
                onPress={iconProps.onPress}
            />
        );
    };

    return props.isFab ? (
        <HashiControlFab
            onClick={onPress}
            isDisabled={props.isDisabled}
            data-cy-audio-toggle
        >
            <AudioIconButton />
        </HashiControlFab>
    ) : (
        <AudioIconButton onPress={onPress} />
    );
};

interface IToggleAudioButtonProps {
    iconSize: string;
    isDisabled: boolean;
    iconColor?: string;
    isFab?: boolean;
}

interface IAudioIconButtonProps {
    onPress?: () => void;
}
