import { faSpinner } from '@fortawesome/pro-regular-svg-icons';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import colors from '../assets/colors.json';

const defaultProps: IHashiSpinnerProps = {
    size: 40,
    color: colors.PrimaryTitleText,
};
export const HashiSpinner = (props: IHashiSpinnerProps) => {
    props = { ...defaultProps, ...props };

    const styles: CSSProperties = {
        fontSize: props.size,
    };

    return (
        <FontAwesomeIcon
            className={'fa-pulse'}
            style={styles}
            icon={faSpinner}
            color={'white'}
        />
    );
};

interface IHashiSpinnerProps {
    size?: number;
    color?: string;
}
