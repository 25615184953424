import React, { useEffect, useState } from 'react';
import RoomView from '../views/RoomView/RoomView';
import { WaitingRoomView } from '../views/WaitingRoomView';
import { JoiningRoomView } from '../views/JoiningRoomView';
import useRoomState from '../twilio/hooks/useRoomState/useRoomState';
import useMainParticipant from '../twilio/hooks/useMainParticipant/useMainParticipant';
import usePublications from '../twilio/hooks/usePublications/usePublications';
import { RoomLoadingView } from '../views/RoomLoadingView';
import { useSessionEndedNavigation } from './useSessionEndedNavigation';
import useVideoContext from '../twilio/hooks/useVideoContext/useVideoContext';
import { RemoteTrackPublication, Track } from 'twilio-video';

const waitTimeOnJoiningRoom = 3000;
const waitTimeBeforeGoingToWaitingRoom = 2000;
const waitTimeBeforeDisconnecting = 3000;
var disconnectedTimeout: NodeJS.Timeout;
var joiningTimeout: NodeJS.Timeout;
var eventDebounceTimeout: NodeJS.Timeout;

export const RoomNavigationBaseRoute = '/room';
export const RoomNavigationController = () => {
    clearTimeout(disconnectedTimeout);
    const roomState = useRoomState();
    const { room } = useVideoContext();
    const sessionEndedNavigation = useSessionEndedNavigation();

    useEffect(() => {
        const goToSessionEnded = () => {
            sessionEndedNavigation.goToSessionEndedView();
        };
        room.on('participantDisconnected', goToSessionEnded);

        return () => {
            room.off('participantDisconnected', goToSessionEnded);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [room]);

    if (roomState === 'disconnected') {
        //If we are disconnected for period of time go to sessionEnded.
        disconnectedTimeout = setTimeout(() => {
            sessionEndedNavigation.goToSessionEndedView();
        }, waitTimeBeforeDisconnecting);
    }

    return roomState !== 'connected' ? <RoomLoadingView /> : <RoomNavigation />;
};

var previousIsInWaitingRoomState = true;
const RoomNavigation = () => {
    const [isInWaitingRoom, setIsInWaitingRoom] = useState(
        previousIsInWaitingRoomState
    );
    const [isJoiningRoom, setIsJoiningRoom] = useState(false);
    const mainParticipant = useMainParticipant();
    const publishedTracks = usePublications(mainParticipant);

    useEffect(() => {
        let dataTrack = publishedTracks.find(
            (x) => x.kind === 'data'
        ) as RemoteTrackPublication;

        if (dataTrack?.isSubscribed) {
            dataTrack?.track?.on('message', dataTrackEventAsync);
        } else {
            dataTrack?.on('subscribed', subscribedEventAsync);
        }

        return () => {
            dataTrack?.track?.off('message', dataTrackEventAsync);
            dataTrack?.track?.off('subscribed', subscribedEventAsync);
        };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [publishedTracks]);

    const subscribedEventAsync = async (track: Track) => {
        track?.on('message', dataTrackEventAsync);
    };

    const dataTrackEventAsync = async (data: string) => {
        clearTimeout(eventDebounceTimeout);
        eventDebounceTimeout = setTimeout(async () => {
            if (data === 'onGoToWaitingRoom') {
                goToWaitingRoom();
            }
            if (data === 'onLeaveWaitingRoom') {
                goToRoom();
            }
        }, 500);
    };

    const goToWaitingRoom = () => {
        setInWaitingRoom(true);
    };

    const goToRoom = () => {
        setIsJoiningRoom(true);
        setInWaitingRoom(false);

        clearTimeout(joiningTimeout);
        joiningTimeout = setTimeout(async () => {
            setIsJoiningRoom(false);
        }, waitTimeOnJoiningRoom);
    };

    const setInWaitingRoom = (value: boolean) => {
        setIsInWaitingRoom(value);
        previousIsInWaitingRoomState = value;
    };

    return isJoiningRoom ? (
        <JoiningRoomView waitTime={waitTimeBeforeGoingToWaitingRoom} />
    ) : isInWaitingRoom ? (
        <WaitingRoomView />
    ) : (
        <RoomView />
    );
};
