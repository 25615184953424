import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { SessionEndedView } from '../views/SessionEndedViews/SessionEndedView';
import { LeaveSessionView } from '../views/SessionEndedViews/LeaveSessionView';
import { LostConnectionView } from '../views/SessionEndedViews/LostConnectionView';
import { IRoute } from './dtos';

export const SessionEndedBaseRoute = '/session-ended';
export var SessionEndedRoutes: IRoute[] = [
    {
        path: `${SessionEndedBaseRoute}/lost-connection`,
        component: LostConnectionView,
    },
    {
        path: `${SessionEndedBaseRoute}/ended`,
        component: SessionEndedView,
    },
    {
        path: `${SessionEndedBaseRoute}/`,
        component: LeaveSessionView,
    },
];

export const SessionEndedNavigationController = () => {
    return (
        <Switch>
            {SessionEndedRoutes.map((route, i) => (
                <Route path={route.path} key={i}>
                    <route.component />
                </Route>
            ))}
        </Switch>
    );
};
