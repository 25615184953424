import styled from 'styled-components';

export const CenterContentContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;
