import Fab from '@material-ui/core/Fab';
import styled from 'styled-components';
import IProps from './propInterfaces/IDisableButtonProps';

const HashiControlFabStyle = {
    background: '#555555',
    height: '100%',
    width: '100%',
    color: '#FFFFFF',
    fontSize: '12px',
};

const HashiControlFab = (props: IProps) => (
    <Wrapper>
        <Fab
            disabled={props.isDisabled}
            onClick={props.onClick}
            style={{ ...HashiControlFabStyle, ...props.style }}
        >
            <>{props.children}</>
        </Fab>
    </Wrapper>
);

const Wrapper = styled.div`
    height: 56px;
    width: 56px;
    font-size: 12px;
    box-sizing: border-box;
    margin: 12px;
    @media (max-width: 370px), (max-height: 370px) {
        margin: 8px;
        height: 50px;
        width: 50px;
        font-size: 10px;
    }
`;

export default HashiControlFab;
