import React from 'react';
import styled from 'styled-components';
import EndCallButton from './EndCallButton';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import useRoomState from '../../../twilio/hooks/useRoomState/useRoomState';
import { ToggleVideoButton } from '../../../components/ToggleVideoButton';
import { ToggleAudioButton } from '../../../components/ToggleAudioButton';
import SettingsGear from './SettingsGear';
import colors from '../../../assets/colors.json';
import { animations } from '../../../assets/animations';

const iconSize = '28px';
const iconColor = colors.PrimaryTitleText;

export default function Controls(props: IControlsProps) {
    const roomState = useRoomState();
    const isReconnecting = roomState === 'reconnecting';

    return (
        <ControlsContainer className={props.isVisible ? 'visible' : 'hide'}>
            <ControlBackground>
                <ControlLayout>
                    <ToggleAudioButton
                        isFab={true}
                        iconSize={iconSize}
                        iconColor={iconColor}
                        isDisabled={isReconnecting}
                    />
                    <ToggleVideoButton
                        isFab={true}
                        iconSize={iconSize}
                        iconColor={iconColor}
                        isDisabled={isReconnecting}
                    />
                    <ShowHideSettings>
                        <SettingsGear />
                    </ShowHideSettings>
                    <FlipCameraButton />
                    <EndCallButton />
                </ControlLayout>
            </ControlBackground>
        </ControlsContainer>
    );
}

const controlsToBottomHeight = '415px';
const showSettingsSize = '730px';

const ControlsContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    z-index: 2;
    bottom: 0;
    position: fixed;
    margin-bottom: 20px;

    &.visible {
        animation: ${animations.slideInBottom} 0.6s ease-out;
    }

    &.hide {
        visibility: hidden;
        transform: translateY(100%);
        transition: all 0.6s;
    }

    @media (max-height: ${controlsToBottomHeight}) {
        justify-content: flex-start;
        align-items: center;
        margin-left: 20px;
        margin-bottom: 0;

        &.visible {
            animation: ${animations.slideInFromLeft} 0.6s ease-out;
        }

        &.hide {
            visibility: hidden;
            transform: translateX(-50%);
            transition: all 0.6s;
        }
    }
`;

const ControlLayout = styled.div`
    display: flex;
    flex-direction: row;

    @media (max-height: ${controlsToBottomHeight}) {
        flex-direction: column;
    }
`;

const ControlBackground = styled.div`
    background-color: ${colors.VideoControlsOverlay};
    border-radius: 16px;
    padding: 8px;
`;

const ShowHideSettings = styled.div`
    @media (max-width: ${showSettingsSize}), (max-height: ${showSettingsSize}) {
        display: none;
    }
`;

interface IControlsProps {
    isVisible: boolean;
}
