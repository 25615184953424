import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import styled from 'styled-components';
import AppStateProvider from './twilio/state/index';
import { VideoProvider } from './twilio/components/VideoProvider';
import { MuiThemeProvider } from '@material-ui/core/styles';
import theme from './twilio/theme';
import { AppViewModelArea } from './ViewModels/AppViewModelArea';
import { HashiBrowserNotSupportedBanner } from './components/HashiBrowserNotSupportedBanner';
import { RootNavigationController } from './NavigationControllers/RootNavigationController';
import { logger, browsersSupportedService } from './services';
import useConnectionOptions from './twilio/utils/useConnectionOptions/useConnectionOptions';

function App() {
  return (
    <Background>
      <Router basename='/'>
        <AppViewModelArea>
          <AppStateProvider>
            <VideoProviderArea />
          </AppStateProvider>
        </AppViewModelArea>
      </Router>
    </Background>
  );
}

const VideoProviderArea = () => {
  const connectionOptions = useConnectionOptions();
  const [browserNotSupported, setBrowserNotSupported] = useState(false);
  const onError = (ex: any) => {
    localStorage.clear();
    logger.logError(ex);
  };

  if (!browsersSupportedService.isSupported()) {
    if (!browserNotSupported) {
      setBrowserNotSupported(true);
    }
  }

  return (
    <VideoProvider onError={onError}>
      <MuiThemeProvider theme={theme}>
        {browserNotSupported && <HashiBrowserNotSupportedBanner />}
        <RootNavigationController />
      </MuiThemeProvider>
    </VideoProvider>
  );
};

const Background = styled.div`
  flex: 1;
  min-width: 250px;
  width: 100%;
  height: 100%;
  background-color: #003c4d;
  display: flex;
  flex-direction: column;
`;
export default App;
