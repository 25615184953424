import React from 'react';
import Participant from '../Participant/Participant';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import styled from 'styled-components';
import useVideoTrackDimensions from '../../hooks/useVideoTrackDimensions/useVideoTrackDimensions';
import usePublications from '../../hooks/usePublications/usePublications';
import useTrack from '../../hooks/useTrack/useTrack';
import { Participant as IParticipant } from 'twilio-video';

export default function ParticipantList() {
    const {
        room: { localParticipant },
    } = useVideoContext();
    const participants = useParticipants();
    const [
        selectedParticipant,
        setSelectedParticipant,
    ] = useSelectedParticipant();

    if (participants.length === 0) return null; // Don't render this component if there are no remote participants.

    return (
        <ParticipantsContainer>
            <LocalParticipant participant={localParticipant} />
            {participants.map((participant) => {
                //Update this later if we want to show more then the Doctor and the patient.
                //We need this looping for wiring up all the audio.
                const hideParticipant = true;
                return (
                    <Participant
                        key={participant.sid}
                        participant={participant}
                        isSelected={participant === selectedParticipant}
                        onClick={() => setSelectedParticipant(participant)}
                        hideParticipant={hideParticipant}
                    />
                );
            })}
        </ParticipantsContainer>
    );
}

const ParticipantsContainer = styled.div``;

const LocalParticipant = (props: ILocalParticipantProps) => {
    const publications = usePublications(props.participant);
    const track = useTrack(publications.find((x) => x.kind === 'video'));
    const dimensions = useVideoTrackDimensions(track as any);
    const isPortrait = (dimensions?.height ?? 0) > (dimensions?.width ?? 0);

    return (
        <LocalParticipantContainer
            style={{ width: isPortrait ? '88px' : '155px' }}
        >
            <Participant
                participant={props.participant}
                isLocalParticipant={true}
            />
        </LocalParticipantContainer>
    );
};

interface ILocalParticipantProps {
    participant: IParticipant;
}

const LocalParticipantContainer = styled.div`
    z-index: 10;
    top: 0;
    right: 0;
    position: absolute;
    width: 100px;
    min-height: 88px;
    margin: 20px;
    box-sizing: border-box;
    border-radius: 16px;
`;
