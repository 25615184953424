import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import colors from '../assets/colors.json';
import strings from '../assets/strings.json';
import { HashiFlexAbsoluteCenterContainer, HashiSpinner } from '../components';
import { useRootNavigation } from '../NavigationControllers/useRootNavigation';
import { Strings } from '../assets/strings';

const loadingTimeoutMilliseconds = 10000;

export const RoomLoadingView = () => {
    var navigation = useRootNavigation();
    var timeoutRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        //Loading View wait time bail out.
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        timeoutRef.current = setTimeout(() => {
            navigation.goToWelcome();
            alert(Strings.UnableToFindRoom);
        }, loadingTimeoutMilliseconds);
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    });

    return (
        <HashiFlexAbsoluteCenterContainer>
            <HashiSpinner size={50} />
            <LoadingText>{strings.Loading}</LoadingText>
            <LookingForRoomText>
                {strings.TryingToFindRoomText}
            </LookingForRoomText>
        </HashiFlexAbsoluteCenterContainer>
    );
};

const LoadingText = styled.div`
    color: ${colors.PrimaryTitleText};
    font-size: 20px;
    line-height: 25px;
    margin-top: 18px;
    max-width: 185px;
    text-align: center;
`;

const LookingForRoomText = styled.div`
    color: ${colors.SecondaryText};
    font-size: 16px;
    line-height: 21px;
    margin-top: 10px;
    max-width: 165px;
    text-align: center;
`;
