import { logger } from './../services/LoggerService';
import { LocalAudioTrack, LocalVideoTrack } from 'twilio-video';
import { useRef } from 'react';
import useVideoContext from '../twilio/hooks/useVideoContext/useVideoContext';
import {
    SELECTED_AUDIO_INPUT_KEY,
    SELECTED_VIDEO_INPUT_KEY,
} from '../constants';
import useIsTrackEnabled from '../twilio/hooks/useIsTrackEnabled/useIsTrackEnabled';

export const useToggleTracksEnabled = () => {
    const {
        room: { localParticipant },
        localTracks,
        getLocalAudioTrack,
        getLocalVideoTrack,
        removeLocalAudioTrack,
        removeLocalVideoTrack,
        onError,
    } = useVideoContext();
    const audioTrack = localTracks.find(
        (track) => track.kind === 'audio'
    ) as LocalAudioTrack;
    const isAudioEnabled = useIsTrackEnabled(audioTrack);
    const selectedAudioDeviceId = window.localStorage.getItem(
        SELECTED_AUDIO_INPUT_KEY
    );
    const selectedVideoDeviceId = window.localStorage.getItem(
        SELECTED_VIDEO_INPUT_KEY
    );
    const previousDeviceIdRef = useRef<string | undefined | null>(
        selectedAudioDeviceId
    );
    const videoTrack = localTracks.find((track) =>
        track.name.includes('camera')
    ) as LocalVideoTrack;
    const previousVideoDeviceIdRef = useRef<string | undefined | null>(
        selectedVideoDeviceId
    );
    const lastRunAudioTimeRef = useRef(0);
    const lastRunVideoTimeRef = useRef(0);

    const disableAllTracksAsync = async () => {
        await disableAudioAsync();
        await disableVideoAsync();
    };

    const enableAllTracksAsync = async () => {
        await enableAudioAsync();
        await enableVideoAsync();
    };

    const disableAudioAsync = async () => {
        if (Date.now() - lastRunAudioTimeRef.current < 500) {
            return;
        }
        lastRunAudioTimeRef.current = Date.now();
        try {
            if (audioTrack) {
                audioTrack.disable();
            }
            removeLocalAudioTrack();
        }
        catch (ex) {
            logger.logError(ex);
            //Log Twilio Error
            onError(ex);
        }
    };

    const enableAudioAsync = async () => {
        if (Date.now() - lastRunAudioTimeRef.current < 500) {
            return;
        }
        if (isAudioEnabled) {
            return;
        }
        lastRunAudioTimeRef.current = Date.now();
        if (audioTrack) {
            audioTrack.enable();
        } else {
            try {
                const track = await getLocalAudioTrack(
                    previousDeviceIdRef.current ?? undefined
                );
                await localParticipant.publishTrack(track, {
                    priority: 'low',
                });
            } catch (ex) {
                logger.logError(ex);
                //Log Twilio Error
                onError(ex);
            }
        }
    };

    const disableVideoAsync = async () => {
        if (Date.now() - lastRunVideoTimeRef.current < 500) {
            return;
        }
        if (!videoTrack?.isEnabled) {
            return;
        }
        lastRunAudioTimeRef.current = Date.now();
        try {
            let deviceId = videoTrack.mediaStreamTrack.getSettings().deviceId;
            if (deviceId) {
                previousVideoDeviceIdRef.current = deviceId;
                window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, deviceId);
            }
            const localTrackPublication = localParticipant?.unpublishTrack(
                videoTrack
            );
            // TODO: remove when SDK implements this event. See: https://issues.corp.twilio.com/browse/JSDK-2592
            (localParticipant as any)?.emit(
                'trackUnpublished',
                localTrackPublication
            );
            removeLocalVideoTrack();
        } catch (ex) {
            logger.logError(ex);
            //Log Twilio Error
            onError(ex);
        }
    };

    const enableVideoAsync = async () => {
        if (Date.now() - lastRunVideoTimeRef.current < 500) {
            return;
        }
        if (!!videoTrack?.isEnabled) {
            return;
        }
        lastRunAudioTimeRef.current = Date.now();
        try {
            const track = await getLocalVideoTrack({
                deviceId: {
                    exact: previousVideoDeviceIdRef.current ?? undefined,
                },
            });
            await localParticipant?.publishTrack(track, {
                priority: 'low',
            });
        } catch (ex) {
            logger.logError(ex);
            //Log Twilio Error
            onError(ex);
        }
    };

    return {
        isAudioEnabled: isAudioEnabled,
        isVideoEnabled: !!videoTrack?.isEnabled,
        enableAllTracksAsync,
        disableAllTracksAsync,
        enableAudioAsync,
        disableAudioAsync,
        enableVideoAsync,
        disableVideoAsync,
    };
};
