import styled from 'styled-components';

export const HashiOpacityVisibilityView = styled.div`
    opacity: ${(props: IOpacityProps) => (props.isVisible ? 1 : 0)};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
`;

interface IOpacityProps {
    isVisible: boolean;
}
