import styled from 'styled-components';
import { HashiCleanButton } from './HashiCleanButton';
import IHasChildren from './propInterfaces/IHasChildren';
import colors from '../assets/colors.json';
import IHasStyle from './propInterfaces/IHasStyle';
import { CSSProperties } from 'react';

const defaultProps = {
    fontSize: 16,
};
export const HashiBorderButton = (props: IHashiBorderButtonProps) => {
    props = { ...defaultProps, ...props };
    props.ContainerStyle = { ...props.ContainerStyle, display: 'inline-block' };

    return (
        <BorderButtonContainer style={props.ContainerStyle}>
            <HashiCleanButton onPress={props.onPress}>
                <BorderButton
                    fontSize={props.fontSize ?? defaultProps.fontSize}
                    style={props.style}
                >
                    {props.children}
                </BorderButton>
            </HashiCleanButton>
        </BorderButtonContainer>
    );
};

export const HashiFilledBorderButton = (props: IHashiBorderButtonProps) => {
    const style = {
        ...props.style,
        background: colors.PrimaryTextButtonBackground,
    };
    return (
        <HashiBorderButton {...props} style={style}>
            {props.children}
        </HashiBorderButton>
    );
};

const BorderButtonContainer = styled.div``;

const BorderButton = styled.div`
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
    color: ${colors.PrimaryTitleText};
    border-radius: 6px;
    padding: 11px 19px;
    font-weight: 500;
    font-size: ${(props: IProps) => props.fontSize}px;
    line-height: 19px;
    border: 1px solid ${colors.PrimaryTextButtonBackground};
    box-sizing: border-box;
`;

interface IProps {
    fontSize: number;
}

interface IHashiBorderButtonProps extends IHasChildren, IHasStyle {
    onPress?: () => void;
    fontSize?: number;
    ContainerStyle?: CSSProperties;
}
