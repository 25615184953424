import React, { useState } from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import { HashiIconButton } from '../../../components/HashiIconButton';
import { faCog } from '@fortawesome/pro-light-svg-icons';
import RoomSettingsModal from '../../../components/RoomSettingsModal';

export default function SettingsMenu() {
    const isMobile = useMediaQuery((theme: Theme) =>
        theme.breakpoints.down('sm')
    );
    const [deviceSettingsOpen, setDeviceSettingsOpen] = useState(false);

    return (
        <>
            {isMobile ? (
                <></>
            ) : (
                <HashiIconButton
                    onPress={() => setDeviceSettingsOpen(true)}
                    icon={faCog}
                    iconColor={'#ffffff'}
                />
            )}
            <RoomSettingsModal
                open={deviceSettingsOpen}
                onClose={() => {
                    setDeviceSettingsOpen(false);
                }}
            />
        </>
    );
}
