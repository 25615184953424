import { keyframes } from 'styled-components';

export const animations = {
    bounceInFromLeft: keyframes`
        0% {
         opacity: 0;
         transform: translateX(-100%);
        }
        25% {
            transform: translateX(30%);
        }
        75% {
            transform: translateX(-5%);
        }
        100% {
            opacity: 100
            transform: translateX(0%);
        }
    `,

    slideInFromLeft: keyframes`
    from {
        opacity: 0;
        transform: translateX(-50%);
    }
    to {
        opacity: 100
        transform: translateX(0%);
    }
    `,

    slideOutToLeft: keyframes`
        from {
            opacity: 100;
            transform: translateX(0%);
        }
        to {
            opacity: 0
            transform: translateX(-50%);
        }
    `,

    bounceInFromRight: keyframes`
        0% {
         opacity: 0;
         transform: translateX(250%);
        }
        25% {
            transform: translateX(251%);
        }
        75% {
            transform: translateX(-10%);
        }
        90% {
            transform: translateX(2%);
        }
        100% {
            opacity: 100
            transform: translateX(0%);
        }
    `,

    fadeIn: keyframes`
        0% {
            opacity: 0;
        }
        80% {
            opacity: 0;
        }
        100% {
            opacity: 100;
        }
    `,

    slideInBottom: keyframes`
        from {
            opacity: 0;
            transform: translateY(50%)
        }
        to {
            opacity: 100;
            transform: translateY(0%)
        }
    `,

    slideOutBottom: keyframes`
        from {
           opacity: 100;
           transform: translateY(0%)
        }
        to {
           opacity: 0;
           transform: translateY(50%)
        }
`,
};
