import { SessionEndedRoutes } from './SessionEndedNavigationController';
import { SessionEndedView } from '../views/SessionEndedViews/SessionEndedView';
import { useHashiRouting } from '../hooks/useHashiRouting';
import { LostConnectionView } from '../views/SessionEndedViews/LostConnectionView';
import { LeaveSessionView } from '../views/SessionEndedViews/LeaveSessionView';

export const useSessionEndedNavigation = () => {
    const showComponent = useHashiRouting(SessionEndedRoutes);

    const goToSessionEndedView = () => {
        showComponent(SessionEndedView);
    };

    const goToLostConnectionView = () => {
        showComponent(LostConnectionView);
    };

    const goToLeaveSessionView = () => {
        showComponent(LeaveSessionView);
    };

    return {
        goToSessionEndedView,
        goToLostConnectionView,
        goToLeaveSessionView,
    };
};
