import { createContext, ReactNode } from 'react';
import useMakePlaylistViewModelValue, {
    IPlaylistViewModel,
} from './useMakePlaylistViewModelValue';

export const PlaylistContext = createContext<IPlaylistContext>(null!);

export const PlaylistViewModelArea = (props: IPlaylistProviderProps) => {
    const playlistViewModelValue: IPlaylistViewModel = useMakePlaylistViewModelValue(
        props.accessToken,
        props.onDisablePlaylist
    );
    var modelValue = { ...playlistViewModelValue, ...props };

    return (
        <PlaylistContext.Provider value={modelValue}>
            {props.children}
        </PlaylistContext.Provider>
    );
};

interface IPlaylistContext extends IPlaylistViewModel, IPlaylistProviderProps {}

interface IPlaylistProviderProps {
    children: ReactNode;
    accessToken: string;
    onDisablePlaylist?: () => void;
}
