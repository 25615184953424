import React, { CSSProperties, useEffect } from 'react';
import styled from 'styled-components';
import { HashiFlexAbsoluteCenterContainer } from '../../components';
import { SessionEndedIcon } from './Components/SessionEndedIcon';
import colors from '../../assets/colors.json';
import strings from '../../assets/strings.json';
import { faCommentAltExclamation } from '@fortawesome/pro-light-svg-icons';
import { useRootNavigation } from '../../NavigationControllers/useRootNavigation';
import { useSessionEndedNavigation } from '../../NavigationControllers/useSessionEndedNavigation';
import {
    HashiBorderButton,
    HashiFilledBorderButton,
} from '../../components/HashiBorderButton';
import { usePauseTracks } from '../../hooks/usePauseTracks';

export const LeaveSessionView = () => {
    const rootNavigation = useRootNavigation();
    const sessionEndedNavigation = useSessionEndedNavigation();
    const { pauseTracksAsync } = usePauseTracks();

    useEffect(() => {
        pauseTracksAsync();
    }, []);

    const goBackPress = () => {
        rootNavigation.goToRoom();
    };

    const endCallPress = () => {
        sessionEndedNavigation.goToSessionEndedView();
    };

    return (
        <HashiFlexAbsoluteCenterContainer>
            <SessionEndedIcon icon={faCommentAltExclamation} />
            <AreYouSureText>{strings.LeaveSessionText}</AreYouSureText>
            <ButtonContainer>
                <HashiFilledBorderButton
                    onPress={goBackPress}
                    ContainerStyle={ButtonContainerStyles}
                >
                    {strings.NoDontLeaveText}
                </HashiFilledBorderButton>
                <HashiBorderButton
                    onPress={endCallPress}
                    ContainerStyle={ButtonContainerStyles}
                >
                    {strings.YesLeaveButtonText}
                </HashiBorderButton>
            </ButtonContainer>
        </HashiFlexAbsoluteCenterContainer>
    );
};

const AreYouSureText = styled.div`
    color: ${colors.PrimaryTitleText};
    font-size: 20px;
    line-height: 25px;
    margin-top: 18px;
    max-width: 285px;
    text-align: center;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: space-around;
`;

const ButtonContainerStyles: CSSProperties = {
    margin: 10,
    marginTop: 40,
};
