import { useState } from 'react';
import styled from 'styled-components';
import usePlaylistViewModel from '../ViewModels/PlaylistViewModelArea/usePlaylistViewModel';
import { Ad } from '../models/Ad';
import { PlaylistLoader } from './PlaylistLoader';
import { VideoAd } from '../models/VideoAd';

export const VideoPlayer = (props: IVideoPlayerProps) => {
    const {
        setIsPaused,
        isPaused,
        onAdEnd,
        on2SecondPlay,
        activeAd,
        onError,
        videoPlayerRef,
    } = usePlaylistViewModel();
    const [isLoading, setIsLoading] = useState(true);
    let isTwoSecondPlayLogged: boolean;

    const videoLoadedEvent = () => {
        setIsLoading(false);
        setIsPaused(false);
    };

    const videoPausedEvent = () => {
        if (isPaused) {
            return;
        }
        setIsPaused(true);
    };

    const videoPlayedFor2SecondsEvent = () => {
        if (isTwoSecondPlayLogged) {
            return;
        }
        isTwoSecondPlayLogged = true;

        on2SecondPlay(props.ad);
    };

    const videoEndedEvent = () => {
        setIsLoading(true);
        onAdEnd(props.ad);
    };

    const videoErroredEvent = (
        error: React.SyntheticEvent<HTMLVideoElement, Event>
    ) => {
        setIsLoading(true);
        if (!(activeAd instanceof VideoAd)) {
            return;
        }
        onError(props.ad);
    };

    return (
        <>
            <PlaylistVideo
                src={props.ad.publicUrl.toString()}
                ref={videoPlayerRef}
                onPlay={videoLoadedEvent}
                onPause={videoPausedEvent}
                onEnded={videoEndedEvent}
                onError={videoErroredEvent}
                onProgress={videoPlayedFor2SecondsEvent}
                muted={true}
                autoPlay={true}
                controls={false}
                playsInline={true}
            ></PlaylistVideo>
            {isLoading && <PlaylistLoader />}
        </>
    );
};

const PlaylistVideo = styled.video`
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
`;

export interface IVideoPlayerProps {
    ad: Ad;
}
