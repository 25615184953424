import { AdType } from './AdType';

export class Ad {
    public static minimumDuration = 3;

    constructor(
        public id: number,
        public name: string,
        public type: AdType,
        public publicUrl: URL,
        public duration?: number,
        public ThumbnailPublicUrl?: URL,
        public uniqueId?: string
    ) {}
    public hasBeenViewed?: boolean;
    public isFavorite?: boolean;
    public AdInterestId?: number;
    public Component?: () => React.FunctionComponentElement<any>;
}
