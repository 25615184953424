import { Ad } from '../models/Ad';

const apiHost = 'api';
class RouteProvider {
    public getPlaylistRoute = () => `${apiHost}/Playlist`;

    public adEventsRoute = (adId: number) => `${apiHost}/Ads/${adId}/Events`;

    public createAdInterestRoute = (ad: Ad) =>
        `${apiHost}/Ads/${ad?.id}/Interests`;

    public deletePatientAdInterestRoute = (adInterestId: number) =>
        `${apiHost}/AdInterests/${adInterestId}`;
}

export const routeProvider = new RouteProvider();
