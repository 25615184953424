import { routes } from './RootNavigationController';
import { TeethCheckView } from '../views/TeethCheckView/TeethCheckView';
import { WelcomeView } from '../views/WelcomeView';
import { QuickWelcomeView } from '../views/QuickWelcomeView';
import { useHashiRouting } from '../hooks/useHashiRouting';
import { SessionEndedNavigationController } from './SessionEndedNavigationController';
import { RoomNavigationController } from './RoomNavigationController';

export const useRootNavigation = () => {
    const showComponent = useHashiRouting(routes);

    const goToDeviceSetup = () => {
        showComponent(TeethCheckView);
    };

    const goToRoom = () => {
        showComponent(RoomNavigationController);
    };

    const goToSessionEnded = () => {
        showComponent(SessionEndedNavigationController);
    };

    const goToWelcome = () => {
        showComponent(WelcomeView);
    };

    const goToQuickWelcome = () => {
        showComponent(QuickWelcomeView);
    };

    return {
        goToDeviceSetup,
        goToRoom,
        goToSessionEnded,
        goToWelcome,
        goToQuickWelcome,
    };
};
