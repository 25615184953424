import React from 'react';
import AvatarIcon from '../../../../icons/AvatarIcon';
import { makeStyles, Theme } from '@material-ui/core';
import { LocalVideoTrack } from 'twilio-video';
import VideoTrack from '../../../VideoTrack/VideoTrack';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        position: 'relative',
        width: '100%',
        height: '100%',
        minHeight: '160px',
        overflow: 'hidden',
        background: 'black',
        borderRadius: 16,
    },
    avatarContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'black',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            '& svg': {
                transform: 'scale(0.7)',
            },
        },
    },
}));

export default function LocalVideoPreview({ identity }: { identity: string }) {
    const classes = useStyles();
    const { localTracks } = useVideoContext();

    const videoTrack = localTracks.find((track) =>
        track.name.includes('camera')
    ) as LocalVideoTrack;

    return (
        <div className={classes.container}>
            {videoTrack ? (
                <VideoTrack track={videoTrack} isLocal />
            ) : (
                <div className={classes.avatarContainer}>
                    <AvatarIcon />
                </div>
            )}
        </div>
    );
}
