import React from 'react';
import { PlaylistPlayer } from './components/PlaylistPlayer';
import { PlaylistViewModelArea } from './ViewModels/PlaylistViewModelArea';

export const PlaylistModule = (props: IPlaylistModuleProps) => {
    return (
        <PlaylistViewModelArea {...props}>
            <PlaylistPlayer />
        </PlaylistViewModelArea>
    );
};

interface IPlaylistModuleProps {
    accessToken: string;
    onDisablePlaylist?: () => void;
}
