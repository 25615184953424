import { useContext } from 'react';
import { AppViewModelContext } from '.';

export const useAppViewModel = () => {
    const context = useContext(AppViewModelContext);
    if (!context) {
        throw new Error(
            'useAppViewModel must be used within a AppViewModelArea'
        );
    }
    return context;
};
