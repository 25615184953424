import React from 'react';

import HashiControlFab from '../../../components/HashiControlFab';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-regular-svg-icons';
import IHasStyle from '../../../components/propInterfaces/IHasStyle';
import { useRootNavigation } from '../../../NavigationControllers/useRootNavigation';

export default function EndCallButton(props: IHasStyle) {
    const { goToSessionEnded } = useRootNavigation();

    const confirmDisconnectClick = async () => {
        goToSessionEnded();
    };

    return (
        <HashiControlFab
            style={{ background: '#FD2E22', fontSize: '12px', ...props.style }}
            onClick={confirmDisconnectClick}
            data-cy-disconnect
        >
            <FontAwesomeIcon icon={faTimes} size='2x' />
        </HashiControlFab>
    );
}
