import { useState } from 'react';
export const useMakeAppViewModelValue = (): IAppViewModelValue => {
    const [customerFirstName, setCustomerFirstName] = useState<string>();
    const [customerLastName, setCustomerLastName] = useState<string>();
    const [roomCode, setRoomCode] = useState<string>();
    const [jwt, setJwt] = useState<string>();
    const [companyName, setCompanyName] = useState<string>();
    const [isRecordingEnabled, setIsRecordingEnabled] = useState<boolean>();

    return {
        customerFirstName,
        setCustomerFirstName,
        customerLastName,
        setCustomerLastName,
        roomCode,
        setRoomCode,
        jwt,
        setJwt,
        companyName,
        setCompanyName,
        isRecordingEnabled,
        setIsRecordingEnabled
    };
};

export interface IAppViewModelValue {
    customerFirstName: string | undefined;
    setCustomerFirstName: (value: string) => void;
    customerLastName: string | undefined;
    setCustomerLastName: (value: string) => void;
    roomCode: string | undefined;
    setRoomCode: (value: string | undefined) => void;
    jwt: string | undefined;
    setJwt: (value: string) => void;
    companyName: string | undefined;
    setCompanyName: (value: string) => void;
    isRecordingEnabled: boolean | undefined;
    setIsRecordingEnabled: (value: boolean) => void;
}
