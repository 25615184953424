import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { logger } from '../../services';
import { Ad } from '../models/Ad';
import { ImageAd } from '../models/ImageAd';
import usePlaylistViewModel from '../ViewModels/PlaylistViewModelArea/usePlaylistViewModel';
import { PlaylistLoader } from './PlaylistLoader';

const defaultDuration = 10000;

let twoSecondTimerRef: NodeJS.Timeout;
let completedTimerRef: NodeJS.Timeout;

export const ImagePlayer = (props: IImagePlayerProps) => {
    const [isLoading, setIsLoading] = useState(true);
    const {
        onAdEnd,
        on2SecondPlay,
        onError,
        isPaused,
        setIsPaused,
    } = usePlaylistViewModel();
    //Need to turn into seconds duration
    const adDuration = props.ad.duration
        ? props.ad.duration * 1000
        : defaultDuration;

    useEffect(() => {
        if (!(props.ad instanceof ImageAd)) {
            return;
        }
        if (isLoading) {
            return;
        }
        if (isPaused) {
            clearTimeout(completedTimerRef);
        } else {
            wireUpDoneEvent();
        }
    }, [isPaused]);

    useEffect(() => {
        return () => {
            //Unmount logic Here
            clearTimeout(twoSecondTimerRef);
            clearTimeout(completedTimerRef);
        };
    }, []);

    const wireUpDoneEvent = () => {
        clearTimeout(completedTimerRef);
        completedTimerRef = setTimeout(() => {
            onAdEnd(props.ad);
        }, adDuration);
    };

    const wireUp2SecondEvent = () => {
        clearTimeout(twoSecondTimerRef);
        twoSecondTimerRef = setTimeout(() => {
            on2SecondPlay(props.ad);
        }, 2000);
    };

    const onLoaded = () => {
        if (!(props.ad instanceof ImageAd)) {
            return;
        }
        setIsPaused(false);
        setIsLoading(false);
        wireUp2SecondEvent();
        wireUpDoneEvent();
    };

    const onErrorEvent = (
        error: React.SyntheticEvent<HTMLImageElement, Event>
    ) => {
        logger.logError(error as any);
        if (!(props.ad instanceof ImageAd)) {
            return;
        }
        onError(props.ad);
    };

    return (
        <>
            <PlaylistImage
                src={props.ad.publicUrl.toString()}
                alt={'Error occured loading.'}
                onError={onErrorEvent}
                onLoad={onLoaded}
            />
            {isLoading && <PlaylistLoader />}
        </>
    );
};

const PlaylistImage = styled.img`
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 8px;
`;

export interface IImagePlayerProps {
    ad: Ad;
}
