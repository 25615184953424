import { faCommentAltSmile } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { HashiFlexAbsoluteCenterContainer } from '../components/HashiContentContainers';
import colors from '../assets/colors.json';
import strings from '../assets/strings.json';
import withStyles, { CSSProperties } from '@material-ui/core/styles/withStyles';
import { LinearProgress } from '@material-ui/core';
import { useIsMounted } from '../hooks/useIsMounted';
//@ts-ignore
import audioFile from './../assets/marimba_drop.wav';
import { useAppViewModel } from '../ViewModels/AppViewModelArea/useAppViewModel';

export const JoiningRoomView = (props: IJoiningRoomProps) => {
    const { audioPlayerRef } = useAppViewModel();
    const [progressValue, setProgressValue] = useState(0);
    const progressIntervalRef = useRef<number>(0);
    const timeoutRef = useRef<NodeJS.Timeout>();
    const isMountedRef = useIsMounted();

    useEffect(() => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
        if (progressValue === 100) {
            return;
        }
        if (isMountedRef.current) {
            timeoutRef.current = setTimeout(() => {
                if (progressIntervalRef.current === 0) {
                    if (audioPlayerRef?.current) {
                        audioPlayerRef.current.src = audioFile;
                        audioPlayerRef.current.play();
                    }
                    progressIntervalRef.current = props.waitTime / 10;
                }
                setProgressValue(progressValue + 10);
            }, progressIntervalRef.current);
        }
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    });

    return (
        <>
            <HashiFlexAbsoluteCenterContainer>
                <FontAwesomeIcon
                    icon={faCommentAltSmile}
                    style={iconStyle}
                    color={colors.PrimaryTextButtonBackground}
                />
                <JoiningText>{strings.JoiningText}</JoiningText>
                <BorderLinearProgress
                    variant='determinate'
                    value={progressValue}
                    style={progressStyle}
                />
            </HashiFlexAbsoluteCenterContainer>
        </>
    );
};

const BorderLinearProgress = withStyles((theme) => ({
    root: {
        height: 10,
        borderRadius: 5,
    },
    colorPrimary: {
        backgroundColor:
            theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
        borderRadius: 5,
        backgroundColor: colors.PrimaryTextButtonBackground,
    },
}))(LinearProgress);

const iconStyle: CSSProperties = {
    fontSize: '70px',
};

const progressStyle: CSSProperties = {
    width: 185,
    margin: 25,
};

const JoiningText = styled.div`
    color: ${colors.PrimaryTitleText};
    font-size: 20px;
    line-height: 25px;
    margin-top: 18px;
    max-width: 185px;
    text-align: center;
`;

interface IJoiningRoomProps {
    waitTime: number;
}
