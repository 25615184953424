import styled from 'styled-components';
import Strings from '../assets/strings.json';
import colors from '../assets/colors.json';
import { useAppViewModel } from '../ViewModels/AppViewModelArea/useAppViewModel';
import { HashiFlexAbsoluteCenterContainer } from '../components/HashiContentContainers';
import { animations } from '../assets/animations';
import { useRootNavigation } from '../NavigationControllers/useRootNavigation';

export const QuickWelcomeView = () => {
    const appModel = useAppViewModel();
    const { goToDeviceSetup } = useRootNavigation();

    setTimeout(() => {
        goToDeviceSetup();
    }, 2500);

    return (
        <WelcomeContainer>
            <CenterEverything>
                <WelcomeSideBySideWrap>
                    <HashiFlexAbsoluteCenterContainer>
                        <WelcomeTextContainer>
                            <HelloText>{Strings.Hello}</HelloText>
                            <NameText>{appModel.customerFirstName}</NameText>
                            <NameText>{appModel.customerLastName}</NameText>
                            <WelcomeText>{Strings.WelcomeText}</WelcomeText>
                            <CompanyText>{appModel.companyName}</CompanyText>
                        </WelcomeTextContainer>
                    </HashiFlexAbsoluteCenterContainer>
                </WelcomeSideBySideWrap>
            </CenterEverything>
        </WelcomeContainer>
    );
};

const WelcomeContainer = styled.div`
    height: 100%;
    min-width: 250px;
    background-color: ${colors.PrimaryBackground};
    flex: 1;
`;

const CenterEverything = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
`;

const WelcomeSideBySideWrap = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const WelcomeTextContainer = styled.div`
    padding: 20px;
    text-align: left;
    flex: 1;
    flex-direction: row;
`;

const HelloText = styled.div`
    font-weight: 500;
    font-size: 28px;
    line-height: 34px;
    color: ${colors.PrimaryTitleText};
    animation: ${animations.bounceInFromLeft} 1.1s ease-in-out;

    @media (max-width: 300px) {
        font-size: 20px;
        line-height: 28px;
    }
`;

const NameText = styled.div`
    margin-top: 2px;
    font-weight: 300;
    font-size: 48px;
    line-height: 53px;
    color: ${colors.PrimaryTitleText};
    animation: ${animations.bounceInFromRight} 1.1s ease-in-out;
`;

const WelcomeText = styled.div`
    margin-top: 28px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: ${colors.SecondaryText};
    animation: ${animations.fadeIn} 1.3s ease-in-out;
`;

const CompanyText = styled.div`
    margin-top: 4px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: ${colors.PrimaryTitleText};
    animation: ${animations.fadeIn} 1.3s ease-in-out;
`;
