import styled from 'styled-components';

export const HashiFlexRowContainer = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
`;

export const HashiFlexColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const HashiFlexAbsoluteCenterContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    height: 100%;
    width: 100%;
    overflow: hidden;
`;
