import React from 'react';
import ParticipantList from '../ParticipantList/ParticipantList';
import MainParticipant from '../MainParticipant/MainParticipant';
import styled from 'styled-components';

export default function Room() {
    return (
        <RoomContainer>
            <MainParticipant />
            <ParticipantList />
        </RoomContainer>
    );
}

const RoomContainer = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
`;
