import React, { useRef, useCallback } from 'react';
import { useHasVideoInputDevices } from '../twilio/hooks/deviceHooks/deviceHooks';
import { useToggleTracksEnabled } from '../hooks/useToggleTracksEnabled';
import { HashiIconToggleButton } from '../components/HashiIconToggleButton';
import colors from '../assets/colors.json';
import { faVideo, faVideoSlash } from '@fortawesome/pro-light-svg-icons';
import HashiControlFab from './HashiControlFab';

const iconColor = colors.PrimaryTitleText;

export const ToggleVideoButton = (props: IToggleVideoButton) => {
    const hasVideoDevices = useHasVideoInputDevices();
    const {
        isVideoEnabled,
        disableVideoAsync,
        enableVideoAsync,
    } = useToggleTracksEnabled();
    const lastClickTimeRef = useRef(0);

    const toggleVideo = useCallback(() => {
        if (Date.now() - lastClickTimeRef.current > 500) {
            lastClickTimeRef.current = Date.now();
            if (isVideoEnabled) {
                disableVideoAsync();
            } else {
                enableVideoAsync();
            }
        }
    }, [isVideoEnabled, disableVideoAsync, enableVideoAsync]);

    const VideoIconButton = (iconProps: IVideoIconProps) => {
        return (
            <HashiIconToggleButton
                toggleOnTap={false}
                iconSize={props.iconSize}
                icon={faVideo}
                selectedIcon={faVideoSlash}
                selected={!isVideoEnabled}
                onPress={iconProps.onPress}
                disabled={!hasVideoDevices || props.isDisabled}
                iconColor={props.iconColor ?? iconColor}
            />
        );
    };

    return props.isFab ? (
        <HashiControlFab
            onClick={toggleVideo}
            isDisabled={!hasVideoDevices || props.isDisabled}
        >
            <VideoIconButton />
        </HashiControlFab>
    ) : (
        <VideoIconButton onPress={toggleVideo} />
    );
};

interface IToggleVideoButton {
    iconSize: string;
    isDisabled: boolean;
    iconColor?: string;
    isFab?: boolean;
}

interface IVideoIconProps {
    onPress?: () => void;
}
