import React from 'react';
import styled from 'styled-components';
import { Strings, ProductStrings } from '../assets/strings';
import colors from '../assets/colors.json';
import { HashiFlexAbsoluteCenterContainer } from '../components/HashiContentContainers';
import { HashiTextInput } from '../components/HashiTextInput';
import { useRootNavigation } from '../NavigationControllers/useRootNavigation';
import { useAppViewModel } from '../ViewModels/AppViewModelArea/useAppViewModel';
import { roomProvider } from '../Provider/RoomProvider';
import { HttpError, logger } from '../services';
import { HashiAsyncButton } from '../components/HashiAsyncButton';

export const WelcomeView = () => {
    const appViewModel = useAppViewModel();
    const { goToQuickWelcome } = useRootNavigation();

    const goToNext = async () => {
        if (!appViewModel.roomCode) {
            alert(Strings.RoomCodeRequired);
            return;
        }

        let formattedCode = appViewModel.roomCode.replaceAll(' ', '');

        try {
            await roomProvider.loadRoomDetailsAsync(
                formattedCode,
                appViewModel
            );
            goToQuickWelcome();
        } catch (ex) {
            logger.logError(ex);
            logger.logMessage('Failed to get room details');
            if (!(ex instanceof Error)) {
                alert(Strings.UnknownErrorOccured);
                return;
            }

            if (ex.message === Strings.RoomCodeRequired) {
                alert(Strings.RoomCodeRequired);
            } else if (
                ex.message === Strings.UnableToFindRoom ||
                (ex instanceof HttpError && ex.statusType === 'ClientError')
            ) {
                alert(Strings.UnableToFindRoom);
            } else if (
                ex.message === Strings.FoundSessionButErrorOccured &&
                !(ex instanceof HttpError)
            ) {
                alert(Strings.FoundSessionButErrorOccured);
            } else {
                alert(Strings.UnknownErrorOccured);
            }
        }
    };

    return (
        <HashiFlexAbsoluteCenterContainer>
            <LoginContainer>
                <WelcomeText>{Strings.WelcomeText}</WelcomeText>
                <ProductTitleText>
                    {ProductStrings.ProductTitle}
                </ProductTitleText>
                <HashiTextInput
                    label={Strings.SessionInputLabel}
                    value={appViewModel.roomCode}
                    onChange={appViewModel.setRoomCode}
                />
                <HashiAsyncButton
                    onPress={goToNext}
                    ContainerStyle={{ paddingTop: 28 }}
                >
                    {Strings.JoinButtonLabel}
                </HashiAsyncButton>
            </LoginContainer>
        </HashiFlexAbsoluteCenterContainer>
    );
};

const LoginContainer = styled.div`
    padding: 20px;
`;

const WelcomeText = styled.div`
    font-size: 48px;
    line-height: 58px;
    font-weight: 300;
    margin-bottom: 8px;
    color: ${colors.PrimaryTitleText};

    @media (max-width: 380px) {
        font-size: 32px;
        line-height: 38px;
    }
`;

const ProductTitleText = styled.div`
    font-weight: 200;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 36px;
    color: ${colors.PrimaryTitleText};
    opacity: 0.64;

    @media (max-width: 380px) {
        font-size: 16px;
        line-height: 20px;
    }
`;
