import React from 'react';
import styled from 'styled-components';
import { HashiFlexAbsoluteCenterContainer } from '../../components';
import { SessionEndedIcon } from './Components/SessionEndedIcon';
import colors from '../../assets/colors.json';
import strings from '../../assets/strings.json';
import { faCommentAltDots } from '@fortawesome/pro-light-svg-icons';

export const LostConnectionView = () => {
    return (
        <HashiFlexAbsoluteCenterContainer>
            <SessionEndedIcon icon={faCommentAltDots} />
            <LostConnectionText>{strings.LostConnection}</LostConnectionText>
            <SessionEndedText>{strings.SessionEnded}</SessionEndedText>
        </HashiFlexAbsoluteCenterContainer>
    );
};

const LostConnectionText = styled.div`
    color: ${colors.PrimaryTitleText};
    font-size: 20px;
    line-height: 25px;
    margin-top: 18px;
    max-width: 185px;
    text-align: center;
`;

const SessionEndedText = styled.div`
    color: ${colors.SecondaryText};
    margin-top: 10px;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
`;
