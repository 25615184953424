import { faCommentAltSmile } from '@fortawesome/pro-light-svg-icons';
import React, { useEffect } from 'react';
import {
    HashiFlexAbsoluteCenterContainer,
    HashiFlexRowContainer,
} from '../../components';
import colors from '../../assets/colors.json';
import strings from '../../assets/strings.json';
import styled from 'styled-components';
import { SessionEndedIcon } from './Components/SessionEndedIcon';
import useVideoContext from '../../twilio/hooks/useVideoContext/useVideoContext';
import { HashiFilledBorderButton } from '../../components/HashiBorderButton';

export const SessionEndedView = () => {
    const {
        room,
        removeLocalAudioTrack,
        removeLocalVideoTrack,
    } = useVideoContext();

    useEffect(() => {
        removeLocalAudioTrack();
        removeLocalVideoTrack();
        room.disconnect?.();
        localStorage.clear();
    });

    //TODO: Update this Value with the app Settings so we go to the correct place.
    const goToSignIn = () => {
        window.open('https://patient.touchmd.com', '_self');
    };

    return (
        <HashiFlexAbsoluteCenterContainer>
            <SessionEndedContainer>
                <SessionEndedIcon icon={faCommentAltSmile} />
                <SessionEndedText>
                    {strings.YourSessionHasEnded}
                </SessionEndedText>
                <HashiFlexRowContainer>
                    <Divider />
                </HashiFlexRowContainer>
                <ThankYouText>{strings.ThanksForJoining}</ThankYouText>
                <ReLiveConsultText>
                    {strings.ReliveYourConsultation}
                </ReLiveConsultText>
                <SignInButtonContainer>
                    <HashiFilledBorderButton onPress={goToSignIn}>
                        {strings.SignIn}
                    </HashiFilledBorderButton>
                </SignInButtonContainer>
            </SessionEndedContainer>
        </HashiFlexAbsoluteCenterContainer>
    );
};

const SessionEndedContainer = styled.div`
    display: flex;
    flex-direction: column;
    margin: 10px;
    align-items: center;
`;

const SessionEndedText = styled.div`
    display: flex;
    color: ${colors.PrimaryTitleText};
    font-size: 20px;
    line-height: 25px;
    margin-top: 18px;
    max-width: 159px;
    text-align: center;
`;

const Divider = styled.div`
    box-sizing: border-box;
    flex: 1;
    flex-direction: row;
    border: 1px solid ${colors.DividerBorderColor};
    margin-top: 40px;
    max-width: 335px;
`;

const ThankYouText = styled.div`
    color: ${colors.PrimaryTitleText};
    font-size: 20px;
    line-height: 25px;
    margin-top: 18px;
    align-self: flex-start;
`;

const ReLiveConsultText = styled.div`
    color: ${colors.SecondaryText};
    font-size: 16px;
    line-height: 21px;
    margin-top: 11px;
    max-width: 333px;
    align-self: flex-start;
`;

const SignInButtonContainer = styled.div`
    align-self: flex-start;
    margin-top: 15px;
`;
