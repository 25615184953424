import React, { useEffect } from 'react';
import styled from 'styled-components';
import { ImageAd } from '../models/ImageAd';
import { VideoAd } from '../models/VideoAd';
import usePlaylistViewModel from '../ViewModels/PlaylistViewModelArea/usePlaylistViewModel';
import { ImagePlayer } from './ImagePlayer';
import { PlaylistControls } from './PlaylistControls';
import { PlaylistError } from './PlaylistError';
import { VideoPlayer } from './VideoPlayer';
import { HashiOpacityVisibilityView } from '../../components/HashiOpacityVisabilityView';

export const PlaylistPlayer = () => {
    const {
        startPlaylistAsync,
        activeAd,
        hasAds,
        isFullScreen,
    } = usePlaylistViewModel();

    useEffect(() => {
        startPlaylistAsync();
    }, []);

    if (!hasAds()) {
        //Return and empty view if no ads are present
        return <></>;
    }

    if (!activeAd?.Component) {
        //Error this shouldn't happen Ever.
        return <PlaylistError />;
    }

    return (
        <PlayerContainer style={isFullScreen ? FullScreenStyles : {}}>
            <PlaylistContainer>
                <PlaylistViewer
                    style={isFullScreen ? FullScreenViewerStyles : {}}
                >
                    <HashiOpacityVisibilityView
                        isVisible={activeAd instanceof ImageAd}
                    >
                        <ImagePlayer ad={activeAd} />
                    </HashiOpacityVisibilityView>
                    <HashiOpacityVisibilityView
                        isVisible={activeAd instanceof VideoAd}
                    >
                        <VideoPlayer ad={activeAd} />
                    </HashiOpacityVisibilityView>
                </PlaylistViewer>
                <PlaylistControls />
            </PlaylistContainer>
        </PlayerContainer>
    );
};

const FullScreenStyles: React.CSSProperties = {
    position: 'fixed',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    flex: 1,
    justifyContent: 'center',
    backgroundColor: 'black',
};

const FullScreenViewerStyles: React.CSSProperties = {
    position: 'unset',
};

const PlayerContainer = styled.div`
    flex: 1;
    width: 100%;
    height: 100%;
`;

const PlaylistViewer = styled.div`
    position: relative;
    padding-bottom: 56.25%;
    overflow: hidden;
    background-color: black;
    border-radius: 8px;
`;

const PlaylistContainer = styled.div`
    margin: 20px;
`;
