class BrowsersSupportedService {
    public browser: BrowserVersion = {} as BrowserVersion;
    public unsupportedBrowsers = {
        Chrome: 55,
        Firefox: 43,
        IE: 11,
        Edge: 79,
        Opera: 43,
        Safari: 10,
    };
    constructor() {
        this.detectLoadBrowser();
    }

    /**
     * Checks if the current browser is Internet Explorer.
     */
    public get isIE(): boolean {
        return this.browser.name === 'IE';
    }

    /**
     * Checks if the current browser is Edge.
     */
    public get isEdge(): boolean {
        return this.browser.name === 'Edge';
    }

    /**
     * Checks if the current browser is from Microsoft (Edge
     * or Internet Explorer).
     */
    public get isMicrosoft(): boolean {
        return this.isIE || this.isEdge;
    }

    /**
     * Checks if the current browser is Firefox.
     */
    public get isFirefox(): boolean {
        return this.browser.name === 'Firefox';
    }

    /**
     * Checks if the current browser is Chrome.
     */
    public get isChrome(): boolean {
        return this.browser.name === 'Chrome';
    }

    /**
     * Checks if the current browser is Safari.
     */
    public get isSafari(): boolean {
        return this.browser.name === 'Safari';
    }

    /**
     * Checks if the current browser is from an Android device.
     */
    public get isAndroid(): boolean {
        return /Android/i.test(navigator.userAgent);
    }

    /**
     * Checks if the current browser is from a BlackBerry device.
     */
    public get isBlackBerry(): boolean {
        return /BlackBerry/i.test(navigator.userAgent);
    }

    /**
     * Checks if the current browser is from a Windows Mobile device.
     */
    public get isWindowsMobile(): boolean {
        return /IEMobile/i.test(navigator.userAgent);
    }

    /**
     * Checks if the current browser is Mobile Safari.
     */
    public get isIOS(): boolean {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    }

    /**
     * Checks if the current browser is a mobile browser.
     */
    public get isMobile(): boolean {
        return (
            this.isAndroid ||
            this.isBlackBerry ||
            this.isWindowsMobile ||
            this.isIOS
        );
    }

    /**
     * Checks if the current browser is supported by
     */
    public isSupported(): boolean {
        if (this.unsupportedBrowsers.hasOwnProperty(this.browser.name)) {
            if (
                +this.browser.version >
                (this.unsupportedBrowsers as any)[this.browser.name]
            ) {
                return true;
            }
        }

        return false;
    }

    private detectLoadBrowser(): void {
        this.browser = (function () {
            var userAgent = navigator.userAgent;
            var version;
            var name =
                userAgent.match(
                    /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
                ) || [];

            if (/trident/i.test(name[1])) {
                version = /\brv[ :]+(\d+)/g.exec(userAgent) || [];
                return {
                    name: 'IE',
                    version: version[1] || '',
                } as BrowserVersion;
            }

            if (name[1] === 'Chrome') {
                version = userAgent.match(/\b(OPR|Edge)\/(\d+)/);
                if (version != null) {
                    return {
                        name: version[1].replace('OPR', 'Opera'),
                        version: version[2],
                    } as BrowserVersion;
                }
            }

            name = name[2]
                ? [name[1], name[2]]
                : [navigator.appName, navigator.appVersion, '-?'];

            if ((version = userAgent.match(/version\/(\d+)/i)) != null) {
                name.splice(1, 1, version[1]);
            }

            return { name: name[0], version: name[1] } as BrowserVersion;
        })();
    }
}

export const browsersSupportedService = new BrowsersSupportedService();

interface BrowserVersion {
    name: string;
    version: string;
}
