import styled from 'styled-components';
import { HashiIconButton } from '../../components/HashiIconButton';
import usePlaylistViewModel from '../ViewModels/PlaylistViewModelArea/usePlaylistViewModel';
import {
    faExpand,
    faHeart,
    faPause,
    faVolumeSlash,
    faPlay,
    faVolumeUp,
} from '@fortawesome/pro-light-svg-icons';
import { faHeart as solidHeart } from '@fortawesome/pro-solid-svg-icons';
import { useState } from 'react';

const iconColor = 'rgba(255, 255, 255, 0.64)';
const iconSize = 20;
const iconSizePixels = `${20}px`;

export const PlaylistControls = () => {
    const playlist = usePlaylistViewModel();

    const PausePlayButton = () => {
        const togglePaused = () => {
            if (playlist.isPaused) {
                playlist.videoPlayerRef.current?.play();
            } else {
                playlist.videoPlayerRef.current?.pause();
            }
            playlist.setIsPaused(!playlist.isPaused);
        };
        return (
            <div>
                {playlist.isPaused ? (
                    <HashiIconButton
                        icon={faPlay}
                        iconSize={iconSizePixels}
                        iconColor={iconColor}
                        onPress={togglePaused}
                    />
                ) : (
                    <HashiIconButton
                        icon={faPause}
                        iconSize={iconSizePixels}
                        iconColor={iconColor}
                        onPress={togglePaused}
                    />
                )}
            </div>
        );
    };

    const MuteButton = () => {
        const toggleMute = () => {
            if (playlist.videoPlayerRef.current) {
                playlist.videoPlayerRef.current.muted = !playlist.isAudioMuted;
            }
            playlist.setIsAudioMuted(!playlist.isAudioMuted);
        };
        const MuteContainer = styled.div`
            //This keeps both both icons the same size;
            min-width: 45px;
        `;
        return (
            <MuteContainer>
                {playlist.isAudioMuted ? (
                    <HashiIconButton
                        isPulseEnabled={true}
                        icon={faVolumeSlash}
                        iconSize={iconSizePixels}
                        iconColor={iconColor}
                        onPress={toggleMute}
                    />
                ) : (
                    <HashiIconButton
                        icon={faVolumeUp}
                        iconSize={iconSizePixels}
                        iconColor={iconColor}
                        onPress={toggleMute}
                    />
                )}
            </MuteContainer>
        );
    };

    const FavoriteButton = () => {
        const [isFavoriteDisabled, setIsFavoriteDisabled] = useState(false);
        const toggleFavoriteAsync = async () => {
            setIsFavoriteDisabled(false);
            await playlist.toggleAdFavoriteAsync();
            setIsFavoriteDisabled(false);
        };

        const HeartContainer = styled.div`
            position: relative;
        `;

        const FavoriteHeartContainer = styled.div`
            position: absolute;
            top: 1px;
            left: 1px;
        `;

        return (
            <HeartContainer>
                <FavoriteHeartContainer>
                    <HashiIconButton
                        visible={playlist.isFavorite}
                        onPress={toggleFavoriteAsync}
                        icon={solidHeart}
                        iconSize={`${iconSize - 2}px`}
                        iconColor={'#DB1A1A'}
                        disabled={isFavoriteDisabled}
                    />
                </FavoriteHeartContainer>
                <HashiIconButton
                    onPress={toggleFavoriteAsync}
                    icon={faHeart}
                    iconSize={iconSizePixels}
                    iconColor={iconColor}
                    disabled={isFavoriteDisabled}
                />
            </HeartContainer>
        );
    };

    return (
        <ControlsContainer
            style={playlist.isFullScreen ? fullScreenStyles : {}}
        >
            <HashiIconButton
                icon={faExpand}
                iconSize={iconSizePixels}
                iconColor={iconColor}
                onPress={playlist.toggleFullScreen}
            />
            <PausePlayButton />
            <MuteButton />
            <FavoriteButton />
        </ControlsContainer>
    );
};

const fullScreenStyles: React.CSSProperties = {
    position: 'fixed',
    bottom: 20,
    left: 20,
    right: 20,
    maxWidth: 500,
    margin: 'auto',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
};

const ControlsContainer = styled.div`
    display: flex;
    flex: 1;
    justify-content: space-around;
    margin-top: 10px;
    background: rgba(255, 255, 255, 0.15);
    border: 1px solid rgba(255, 255, 255, 0.2);
    box-sizing: border-box;
    border-radius: 8px;
`;
