import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { WelcomeView } from '../views/WelcomeView';
import { TeethCheckView } from '../views/TeethCheckView/TeethCheckView';
import { QuickWelcomeView } from '../views/QuickWelcomeView';
import { IRoute } from './dtos';
import {
    SessionEndedBaseRoute,
    SessionEndedNavigationController,
} from './SessionEndedNavigationController';
import { RoomLoadingView } from '../views/RoomLoadingView';
import {
    RoomNavigationBaseRoute,
    RoomNavigationController,
} from '../NavigationControllers/RoomNavigationController';

export const routes: IRoute[] = [
    {
        path: '/device-setup',
        component: TeethCheckView,
    },
    {
        path: RoomNavigationBaseRoute,
        component: RoomNavigationController,
    },
    {
        path: SessionEndedBaseRoute,
        component: SessionEndedNavigationController,
    },
    {
        path: '/quick-welcome',
        component: QuickWelcomeView,
    },
    {
        path: '/welcome',
        component: WelcomeView,
    },
    {
        path: '/',
        component: RoomLoadingView,
    },
];

export const RootNavigationController = () => {
    return (
        <Switch>
            {routes.map((route, i) => (
                <Route path={route.path} key={i}>
                    <route.component />
                </Route>
            ))}
        </Switch>
    );
};
